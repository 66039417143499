import React from 'react'
import BaseGridTable from './BaseGridTable'
import { formatValue } from '../helpers/ReportHelpers'
import { equal, copyToClipboard } from '../helpers/GeneralHelpers'
import RequirePermission from './RequirePermission'
import { is } from '../helpers/PermissionHelpers'
import WordWrap from './Generic/WordWrap'
import { notifySuccess } from '../helpers/NotificationManager'
import { setGridRef, setMasterDataItem, setMasterDataNextButtonState, showMasterDataDialog } from '../helpers/MasterDataManager'
import { hideDiffViewDialog } from '../helpers/DiffViewManager'
import ShowIf from './Generic/ShowIf'
import ReactMarkdown from 'react-markdown'
import ReactTooltip from 'react-tooltip'

import '../css/report.css'
/*
Props description:

# id (required):
Needed for the copy to clipboard to work in a multi-data grid environment

# columns (required):
[{name: "my col", type: "id", index: 0, settings: {...}}, {name: "my other col", type: "decimal", index: 1, settings...}, ...]
The columns must contain one, and only one id column.
The index represent the index of that column in the data.
The columns should be shown columns and should be in order.
The settings are optional and can set following properties:
- wrapper, a function that takes a formatted value and a raw value and produces a JSX output which is applied to all values in the column: function, default = undefined
- mergeHeaderWithPrevious: boolean, default = false.
- headerClassName, classes provided to the th element for this column: string, default = "".
- columnClassName, classes provided to td elements in table for this column: string, default = "".
- colgroupClassName, classes provided to col elements in table colgroup for this column: string, default = "".
- showDecimals, decide if a column should display decimals: boolean, default = false.
- disableSorting, determines if the table header is clickable for sorting: boolean, default = false.
- disableAutoFit, determines if the table header can be automatically fitted by clicking the button: boolean, default = false.
- disablePercent, determines if the table header has percent button, default = false.

# rows (required):
## Example 1:
[
    {data: ["1", 2.8889, ...], id: "1"},
    {data: ["2", 9.4542, ...], id: "2"},
    ...
]
## Example 2:
[
    {data: ["1", 2.8889, ...], id: {key: 2, id:"string"}},
    {data: ["2", 9.4542, ...], id: {key: 12, id:"string3"}},
    ...
]
The inner array length must match the biggest index value (plus one) of the columns.
Data is the shown data, and id is the value returned e.g. onRowFocus, getLatestComment and onSelect.

# className:
A string of one or more classes to be applied to the <table> component.

# getLatestComment:
If function given, an extra preceding column will be inserted. The function is expected to return a string or null. Null should be given if there's no latest comment.
The value of the id column in a row will be given as parameter.

# showOpenButton:
A boolean value if a cell with a open button is shown. Master data will be opened upon click.

# onSort:
A callback function called when the user sorts a column.
The column object (name and index) and the sort direction will be given as parameter.

# showDecimals:
A boolean value if the number representation should include decimals. This affects the whole table.

# enableCheckboxes:
A boolean value if a preceding column should be added enabling the user to select rows.

# onSelect:
A callback function called when the set of selected items changes. 
The array of selected row id's are given as parameter.

# onRowFocus:
A callback function called when a new row has been focused.
The value of the id column for that row will be given as parameter

# onRowBlur:
A callback function called when no row has focus.
The value of the id column for that row will be given as parameter

# defaultSortColumn:
A string value of the default column to be sorted for.

# defaultSortDirection:
A boolean value for the sorting direction (ascending (false) or descending (true)). Used for initial setting and as default setting when a new column is sorted for.

# openClicked:
A function to provide a custom action when the open button has been clicked. Providing a function disables default behavior which is opening the row in the master data popup.
The row id and the row itself are given as arguments.

# getFocusedRow:
A getter function that returns the currently focused row.

# disableRowSelection
A boolean value deciding if rows are clickable (and being able to be colored yellow).

# disableCopyID
A boolean value deciding if the ID column has a copy buttton.

# enablePercent
A boolean value deciding if headers have a percent button.

# onClickPercent
A callback function called when the percent button in the column headers is clicked. The new array of booleans is returned indicating if the column at the index has percent enabled.

*/

class DataGrid extends React.Component {
    /* LIFE CYCLE */
    constructor(props) {
        super(props)
        this.state = {
            sortColumn: this.props.defaultSortColumn ? this.props.defaultSortColumn : undefined,
            sortDirection: this.props.defaultSortDirection ? this.props.defaultSortDirection : true,
            selectedRows: [],
            focusedRow: null,
            autoFit: this.props.autoFitArr ? this.props.autoFitArr : [],
            lastClickedIndex: 0,
            lastOpenIndex: 0,
            showPercent: [],
            totalRow: null,
            hastotalrow: this.props.hastotalrow ? this.props.hastotalrow : false
        }
    }

    componentDidMount() {
        if (this.props.fromChecklist) {
            const int = setInterval(() => {
                if (this.refs.baseTable) {
                    this.forceUpdate()
                    clearInterval(int)
                }
            }, 100)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.sorted) {
            this.setState({ sorted: false })
        }

        if (this.props.fromChecklist && JSON.stringify(prevProps.autoFitArr) !== JSON.stringify(this.props.autoFitArr)) { // only used from checklist. The x number of datagrids needs to have autoFit updated from here
            this.setState({ autoFit: this.props.autoFitArr })
        }

        if (this.refs.baseTable) {
            this.startAutoFit()
        }
    }

    startAutoFit() {
        this.state.autoFit.forEach((item, index) => { // this should be optimized by making sure we only enter once here. Now we enter x amount of times (alot!).
            if (item && item.fit) {
                let columnToResize = window.$('#th-' + index + "-" + this.props.id)
                for (let k = 0; k < columnToResize.length; k++) {
                    columnToResize.css('width', item.style)
                }
            } else if (this.props.fromChecklist && item && !item.fit) {
                let columnToResize = window.$('#th-' + index + "-" + this.props.id)
                columnToResize.css('width', '')
            }
        })
    }

    /* PUBLIC */

    getHTML(includeComments, includeColumns) {
        let getComment = (id) => {
            if (includeComments && this.props.getLatestComment) {
                let comment = this.props.getLatestComment(id)
                if (comment) {
                    return '</td><td>' + comment.comment 
                }
                return ''
            }
            return ''
        }

        let columnHTML = includeColumns ? '<tr><th>'  + this.props.columns.map(c => c.name).join('</th><th>')+ (includeComments ? '</th><th>Comments' : '') + '</th></tr>' : ''

        let rows = this.props.rows;
        let selectedRows = this.state.selectedRows;
        //Filter the rows if any rows are selected
        if(selectedRows.length !== 0){
            // Handle different types of row objects
            //Gridview
            if(selectedRows.some(r => typeof r === "string")){
                rows = rows.filter(r => selectedRows.includes(r.id));
            } 
            //Checklist
            else{
                const selectedRowIds = selectedRows.map(r => r.key);
                rows = rows.filter(r => selectedRowIds.includes(r.id.key));
            }
        }

        let rowHTML = '<tr><td>' + rows.map(r => {
            if (!r.id || (r.id && r.id.key && r.id.key !== 'sum') || (r.id && !r.id.key)) {
                let shownDataCells = this.props.columns.map(c => {
                    let showDecimals = this.props.showDecimals ? this.props.showDecimals : (c.showDecimals ? c.showDecimals : false);
                    return formatValue(c.type === "decimal", r.data[c.index], showDecimals)
            })//Filters out data cells that are not a shown column
            return shownDataCells.join('</td><td>') + getComment(r.id)  //Makes inner arrays to HTML
            }
            else {
                return null
            }
        }).join('</td></tr><tr><td>') + '</td></tr>' //Makes outer array to HTML

        let totalRow = rows.filter(r => r.id && r.id.key && r.id.key === 'sum')
        let totalRowHTML = ''

        if (totalRow && totalRow.length > 0) {

            totalRowHTML ='<tr><th>' + totalRow.map(r => {
                let shownDataCells = this.props.columns.map(c => {
                    let showDecimals = this.props.showDecimals ? this.props.showDecimals : (c.showDecimals ? c.showDecimals : false);
                    return formatValue(c.type === "decimal", r.data[c.index], showDecimals)
            })
            return shownDataCells.join('</th><th>') + getComment(r.id)   //Makes inner arrays to HTML
            }).join('</th><th></th><th>')  + '</th></tr>'
        }
        //Maps rows to a filtered list of their cells that cointain those cells that have a column shown. This is joined to an HTML string
        let style = '<style>th { white-space: nowrap; } td {white-space: nowrap; }</style>'
        
        return style + '<table>' + columnHTML + rowHTML + totalRowHTML + '</table>'
    }

    getCSV(includeComments, includeColumns) {
        let sanitizeCSVValue = (val) => {
            return '"' + val.toString().replace('"', '""') + '"';
        }

        let getComment = (id) => {
            if (includeComments && this.props.getLatestComment) {
                let comment = this.props.getLatestComment(id)
                if (comment) {
                    return sanitizeCSVValue(comment) + ','
                }
                return '"",'
            }
            return ''
        }

        let columnCSV = includeColumns ? (includeComments ? '"Comments",' : '') + this.props.columns.map(c => sanitizeCSVValue(c.name)).join(',') + '\r\n' : ''

        let rowCSV = this.props.rows.map(r => {
            let shownDataCells = this.props.columns.map(c => formatValue(c.type === "decimal", r.data[c.index], this.props.showDecimals))//Filters out data cells that are not a shown column
            let sanitized = shownDataCells.map(cell => { //places strings in quotes and replaces literal " with ""
                if (typeof cell === 'string') {
                    return sanitizeCSVValue(cell)
                }
                return cell
            })
            return getComment(r.id) + sanitized.join(',') //Makes inner arrays to CSV
        }).join('\r\n') //Makes outer array to CSV

        //Maps rows to a filtered list of their cells that cointain those cells that have a column shown. This is joined to an CSV string
        return '' + columnCSV + rowCSV + '\r\n'
    }

    getDiffHTML(includeComments, includeColumns) {
        let getComment = (id) => {
            // if (includeComments && this.props.getLatestComment) {
            //     let comment = this.props.getLatestComment(id)
            //     if (comment) {
            //         return comment + '</td><td>'
            //     }
            //     return '</td><td>'
            // }
            return ''
        }
        const tmpColumns = this.props.columns
        tmpColumns.pop()

        let columnHTML = includeColumns ? '<tr><th>' + (includeComments ? 'Comments</th><th>' : '') + tmpColumns.map(c => c.name).join('</th><th>') + '</th></tr>' : ''

        let rows = this.props.rows;
        let selectedRows = this.state.selectedRows;
        //Filter the rows if any rows are selected
        if(selectedRows.length !== 0){
            if(selectedRows.some(r => typeof r === "string")){
                rows = rows.filter(r => selectedRows.includes(r.id));
            } 
        }

        let rowHTML = '<tr><td>' + rows.map((r, i) => {
            // let dataCells = []
            // let count = 0
            // for (const key in r) {
            //     if (r.hasOwnProperty(key)) {
            //         const element = r[key];
            //         console.log(element);
            //         dataCells.push(formatValueIntl(count > 2, element, showDecimals))
            //         count++
            //     }
            // }            
            let dataCells = tmpColumns.map(c => formatValue(c.type === "decimal", r.data[c.index], this.props.showDecimals))//Filters out data cells that are not a shown column
            return getComment(r.item_id) + dataCells.join('</td><td>') //Makes inner arrays to HTML
        }).join('</td></tr><tr><td>') + '</td></tr>' //Makes outer array to HTML

        //Maps rows to a filtered list of their cells that cointain those cells that have a column shown. This is joined to an HTML string
        return '<table>' + columnHTML + rowHTML + '</table>'
    }

    getSelectedRowIds() {
        return this.state.selectedRows
    }

    getSettings() {
        return this.state
    }

    setSorting(direction, column) {
        this.setState({ sortColumn: column, sortDirection: direction })
    }

    setAutoArr(arr) {
        this.setState({ autoFit: arr })
    }

    getFocusedRow() {
        return this.state.focusedRow
    }

    /* PRIVATE */

    forceToggleSelection = (id) => {
        this.toggleSelection(id, true)
    }

    toggleSelection = (id, force) => {
        if (!this.isSelected(id)) {
            if (force) this.setState({ selectedRows: [] }, () => this.select(id))
            else this.select(id)
        } else {
            this.deselect(id)
        }
    }

    select = (id) => {
        let selectedRows = this.state.selectedRows.slice()
        if (!this.isSelected(id)) {
            selectedRows.push(id)
        }
        this.setState({ selectedRows: selectedRows })
        if (this.props.onSelect) this.props.onSelect(selectedRows, id)
    }

    getPrefetchableIds = (bucketId) => {
        const { rows} = this.props

        const result = []
        if(bucketId === this.props.bucketId){ //only prefetch for same bucket!
            for(let i = -2; i <= 2; i++){
                const nextIndex = this.state.lastOpenIndex + i
                if(rows[nextIndex]){
                    let id = rows[nextIndex].id

                    if ((this.props.isDiff || this.props.isSum) && nextIndex === 0){
                        continue; //skip
                    }

                    if(id.key !== undefined){
                        if(id.key === "sum"){
                            continue; //skip
                        }

                        id = id.key
                    }
                    
                    result.push(id)
                }
            }
        }
        return result
    }

    next = (increment) => {
        const { rows, fromChecklist } = this.props
        const nextIndex = increment ? this.state.lastOpenIndex + 1 : this.state.lastOpenIndex - 1
        let newId = rows[nextIndex] ? rows[nextIndex].id : null

        if((this.props.isDiff || this.props.isSum) && nextIndex === 0){
            newId = null;
        }

        if(newId.key !== undefined){
            if(newId.key === "sum"){
                newId = null
            }
        }

        if (!newId) {
            // if index is out of bounds, 1 signals the upper bound and -1 signals the lower bound
            setMasterDataNextButtonState(nextIndex >= rows.length - 1 ? 1 : nextIndex <= 0 ? -1 : 0)
            return
        }

        this.focus(newId)
        setMasterDataItem(fromChecklist ? newId.key : newId)
        this.setState({ lastOpenIndex: nextIndex })

        // if index is out of bounds, 1 signals the upper bound and -1 signals the lower bound
        setMasterDataNextButtonState(nextIndex >= rows.length - 1 ? 1 : nextIndex <= 0 ? -1 : 0)
    }

    deselect = (id) => {
        let selectedRows = this.state.selectedRows.filter(r => !equal(r, id))
        this.setState({ selectedRows: selectedRows })
        if (this.props.onSelect) this.props.onSelect(selectedRows)
    }

    deselectMultiple = (ids) => {
        let selectedRows = this.state.selectedRows.filter(r => ids.findIndex(id => equal(r, id)) === -1)
        this.setState({ selectedRows: selectedRows })
        if (this.props.onSelect) this.props.onSelect(selectedRows)
    }

    isSelected = (id) => {
        let rows = this.state.selectedRows
        for (var i = 0; i < rows.length ; i++) {
            if (equal(rows[i], id)) return true
        }
        return false
    }

    selectAll = () => {
        let rows = this.props.rows
        let selectedRows = []
        for (let i = 0; i < rows.length; i++) {
            selectedRows.push(rows[i].id)
        }
        this.setState({ selectedRows: selectedRows })
        if (this.props.onSelect) this.props.onSelect(selectedRows)
    }

    deselectAll = () => {
        this.setState({ selectedRows: [] })
        if (this.props.onSelect) this.props.onSelect([])
    }

    toggleFullSelection = e => {
        e.stopPropagation()
        if (this.state.selectedRows.length === this.props.rows.length) {
            this.deselectAll()
        } else {
            this.selectAll()
        }
    }

    focus = id => {
        this.setState({ focusedRow: id })
        if (this.props.onRowFocus) this.props.onRowFocus(id)
    }

    blur = (id, cancelCallback = false) => {
        let _id = id
        this.setState({ focusedRow: null }, () => {
            if (this.props.onRowBlur && !cancelCallback) this.props.onRowBlur(_id)
        })
    }

    toggleFocus = id => {
        if (!equal(this.state.focusedRow, id)) {
            this.focus(id)
        } else {
            this.blur(id)
        }
    }

    selectRange(id, index) {
        let rows = this.props.rows
        let lowerIndex = Math.min(index, this.state.lastClickedIndex)
        let upperIndex = Math.max(index, this.state.lastClickedIndex)
        if (lowerIndex >= rows.length) lowerIndex = rows.length - 1
        else if (lowerIndex < 0) lowerIndex = 0
        if (upperIndex >= rows.length) upperIndex = rows.length - 1
        else if (upperIndex < 0) upperIndex = 0
        let selected = this.isSelected(id)
        let selectedRows = this.state.selectedRows.slice()
        for (let i = lowerIndex; i <= upperIndex; i++) {
            let index = selectedRows.findIndex(row => JSON.stringify(row) === JSON.stringify(rows[i].id))
            if (selected) {
                if (index !== -1) selectedRows.splice(index, 1)
            } else {
                if (index === -1) selectedRows.push(rows[i].id)
            }
        }
        this.setState({ selectedRows: selectedRows })
        if (this.props.onSelect) this.props.onSelect(selectedRows)
    }

    rowClicked = (id, event, checkbox = false, index, disabled) => {
        if(this.props.isSum && index === 0)
            return;

        if (this.props.clickReport && this.props.rows[index]?.key) {
            console.log(this.props.rows[index].key)
            this.props.clickReport(this.props.rows[index].key)
        }

        if (this.props.disableRowSelection || disabled) return
        if (event.shiftKey) {
            this.selectRange(id, index)
        } else if (event.ctrlKey || checkbox) {
            this.toggleSelection(id)
        } else {
            this.toggleSelection(id, true)
        }
        if (checkbox) {
            event.stopPropagation()
        }

        this.setState({ lastClickedIndex: index })
    }

    openClicked = (id, e, index, showComments) => {
        e.stopPropagation()
        if (this.props.openClicked) {
            this.props.openClicked(id, this.props.rows[index].data)
        } else {
            if (this.props.isDiff) {
                hideDiffViewDialog(true)
            }
            this.focus(id)
            setGridRef(this)
            showMasterDataDialog(this.props.bucketId, this.props.fromChecklist ? id.key : id, undefined, index >= this.props.rows.length - 1 ? 1 : index <= 0 ? -1 : 0, this.props.isDiff, this.props.columnDescriptions, this.props.editMode, showComments )
        }
        this.setState({ lastOpenIndex: index })
    }

    sort(col) {
        if (col.disableSorting) return
        if (this.state.sortColumn === col.name) {
            if (this.props.onSort) this.props.onSort(col, !this.state.sortDirection)
            this.setState({ sortDirection: !this.state.sortDirection, sorted: true })
        } else {
            let sortDirection = this.props.defaultSortDirection ? this.props.defaultSortDirection : true
            if (this.props.onSort) this.props.onSort(col, sortDirection)
            this.setState({ sortDirection: sortDirection, sortColumn: col.name, sorted: true })
        }
    }

    getColumnSortIcon = (column, disabled) => {
        if (disabled) return null
        let className = "float-right arrow-align abc-sort-icon margin-left-5px fa fa-long-arrow-"
        if (this.state.sortDirection) className += "up"
        else className += "down"
        if (column !== this.state.sortColumn) className += " hidden-sort-arrow"
        return <span className={className} />
    }

    reselect = (rows) => {
        let selectedRows = []
        for (let i = 0; i < this.props.rows.length; i++) {
            for (let j = 0; j < rows.length; j++) {
                if (equal(this.props.rows[i].id, rows[j])) {
                    selectedRows.push(rows[j])
                }
            }
        }
        this.setState({ selectedRows: selectedRows })
        if (this.props.onSelect) this.props.onSelect(selectedRows)
    }

    onClickPercent = (event, columnIndex) => {
        event.stopPropagation()
        const showPercent = this.state.showPercent.slice()
        showPercent[columnIndex] = !showPercent[columnIndex]
        this.setShowPercent(showPercent)
    }

    setShowPercent = showPercent => {
        this.setState({ showPercent })
        if (this.props.onClickPercent) this.props.onClickPercent(showPercent)
    }

    autoFitColumn = (e, index, fromGridview, isActiveCat) => {
        if (e) {
            e.stopPropagation()
        }

        let arr = Object.assign([], this.state.autoFit)
        if (!fromGridview) {
            if (arr[index] && arr[index].fit) {
                arr[index].fit = !arr[index].fit
            } else {
                arr[index] = { fit: true, style: '' }
            }
        }

        let longestString = this.props.columns[index].name
        let longestStringDataIndex = this.props.columns[index].index
        let columnToResize = window.$('#th-' + index + '-' + this.props.id)
        if (!arr[index].fit) {
            columnToResize.css('width', '')
        }
        if (arr[index].fit) {
            this.props.rows.forEach((row, i) => {
                if (typeof row.data[longestStringDataIndex] === "number") {
                    if (row.data[longestStringDataIndex].toFixed(2).toString().length > longestString.length) {
                        longestString = row.data[longestStringDataIndex].toFixed(2).toString()
                    }
                } else {
                    if (row.data[longestStringDataIndex] && row.data[longestStringDataIndex].toString().length > longestString.length) {
                        longestString = row.data[longestStringDataIndex].toString()
                    }
                }

            })

            window.$('#for-dynamic-cell-width-measurement').empty()
            window.$('#for-dynamic-cell-width-measurement').show()
            window.$('#for-dynamic-cell-width-measurement').append('<span id="width-result">' + longestString + '</span>')
            let elementWidth = window.$('#width-result').width() + 50 + (isActiveCat ? 22 : 0)
            window.$('#for-dynamic-cell-width-measurement').hide()

            arr[index].style = `${elementWidth}px`
            for (let k = 0; k < columnToResize.length; k++) {
                columnToResize.css('width', elementWidth + 'px')
            }
        }
        if (!fromGridview) {
            this.setState({ autoFit: arr }, () => {
                if (this.props.setAutoFitArr)
                    this.props.setAutoFitArr(arr)
            })
        }
    }

    getPercentIcon(index) {
        if (this.state.showPercent[index]) {
            return ''
        }
        return 'abc-autofit-column-icon'
    }

    getAutoFitIcon(index) {
        if ((this.state.autoFit[index] && this.state.autoFit[index].fit)) {
            return ''
        }
        return 'abc-autofit-column-icon'
    }



    render() {
        let rows = this.props.rows
        let columns = this.props.columns
        if (!rows || rows.length < 1 || !columns || columns.length < 1) return null

        let headerColumnSpans = [] //array of how many columns a header should span
        let j = 1 //keeps track how many headers has been merged in a row
        columns.forEach((col, i) => {
            if (col.mergeHeaderWithPrevious) {
                if (i === 0) throw Error("DataGrid Error: Cannot merge 0th column header with previous column header")
                headerColumnSpans[i - j]++
                headerColumnSpans.push(0)
                j++
            } else {
                headerColumnSpans.push(1)
                j = 1
            }
        })

        return (
            <BaseGridTable ref="baseTable" id={this.props.id} className={this.props.className}>
                <thead id="align-top">
                    <tr>
                        <RequirePermission perms={is.itemAccessor}>
                            {
                                this.props.showOpenButton ? <th className="datagrid-icon-cell center sticky-th open-datagrid-cell" style={{ top: this.props.sticky + "px" }}></th> : null
                            }
                        </RequirePermission>
                        {
                            this.props.enableCheckboxes ? <th className="datagrid-icon-cell center sticky-th checkbox-datagrid-cell" style={{ top: this.props.sticky + "px" }} onClick={e => this.toggleFullSelection(e)}><input type="checkbox" className="vertical-align-middle" checked={this.state.selectedRows.length === this.props.rows.length} onChange={e => this.toggleFullSelection(e)} /></th> : null
                        }
                        {
                            this.props.getLatestComment ? <th className="comment-icon-cell center sticky-th" style={{ top: this.props.sticky + "px" }} /> : null
                        }
                        {
                            columns.map((c, i) => {
                                if (c.mergeHeaderWithPrevious) return null
                                return (
                                    <th key={i} id={'th-' + i + '-' + this.props.id} onClick={() => this.sort(c)} colSpan={headerColumnSpans[i]} className={`${c.disableSorting ? '' : 'abc-click'} sticky-th ${this.props.wrapHeaderText ? 'white-space-normal overflow-wrap-break-word' : ''} ${c.headerClassName ? c.headerClassName : ''}`} style={{ top: this.props.sticky + "px" }}>
                                        {
                                            !this.props.wrapHeaderText ?
                                                <React.Fragment>
                                                    {
                                                        !this.props.enablePercent || c.disablePercent ? null :
                                                            <span className={`fa fa-percent float-right margin-left-2px margin-top-3px abc-click ${this.getPercentIcon(i)}`} onClick={e => this.onClickPercent(e, i)} />
                                                    }
                                                    {
                                                        c.disableAutoFit ? null :
                                                            <span className={`fa fa-arrows-h float-right margin-left-2px margin-top-3px abc-click ${this.getAutoFitIcon(i)}`} onClick={e => this.autoFitColumn(e, i, false, c.name === this.props.activeCategoryColumn)} />
                                                    }
                                                    {this.getColumnSortIcon(c.name, c.disableSorting)}
                                                    {this.props.columnDescriptions?.[c.name] ? (
                                                        <div  className="float-right" style={{display: "inline"}}>
                                                            <i data-tip data-for={`tooltip-${c.index}`} style={{zIndex: 200, position: "relative"}} className="fa fa-info-circle margin-left-2px margin-right-10px glyphicon-info-gray"></i>
                                                            <ShowIf if={this.props.columnDescriptions[c.name]}>
                                                                    <ReactTooltip id={`tooltip-${c.index}`} type='dark' effect='solid' place='bottom'><ReactMarkdown children={this.props.columnDescriptions[c.name]}/></ReactTooltip>
                                                            </ShowIf>
                                                        </div>
                                                    ) : ""}
                                                </React.Fragment>
                                                : null
                                        }
                                        {c.name}
                                        {this.props.aggregations && this.props.aggregations[i] ? <span className='aggregation-description'>{this.props.aggregations[i]}</span> : null}
                                        {this.props.activeCategoryColumn === c.name ? 
                                            <div className='inline-block pl-2'><i className='fa fa-th' style={this.props.isSum ? { marginTop: -2 } : null} /></div> : null}
                                        
                                        {
                                            this.props.wrapHeaderText ?
                                                <React.Fragment>
                                                    {this.props.columnDescriptions?.[c.name] ? (
                                                        <div style={{display: "inline"}}>
                                                            <i data-tip data-for={`tooltip-${c.index}`} className="fa fa-info-circle margin-left-10px glyphicon-info-gray"></i>
                                                            <ShowIf if={this.props.columnDescriptions[c.name]}>
                                                                    <ReactTooltip id={`tooltip-${c.index}`} type='dark' effect='solid' place='bottom'><ReactMarkdown children={this.props.columnDescriptions[c.name]}/></ReactTooltip>
                                                            </ShowIf>
                                                        </div>
                                                    ) : ""}
                                                    {
                                                        !this.props.enablePercent || c.disablePercent ? null :
                                                            <span className={`fa fa-percent float-right margin-left-2px margin-top-3px abc-click ${this.getPercentIcon(i)}`} onClick={e => this.onClickPercent(e, i)} />
                                                    }
                                                    {
                                                        c.disableAutoFit ? null :
                                                            <span className={`fa fa-arrows-h float-right margin-left-2px margin-top-3px abc-click ${this.getAutoFitIcon(i)}`} onClick={e => this.autoFitColumn(e, i, false, c.name === this.props.activeCategoryColumn)} />
                                                    }
                                                    {this.getColumnSortIcon(c.name, c.disableSorting)}
                                                </React.Fragment>
                                                : null
                                        }
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.map((row, i) => { 
                            if (this.props.hastotalrow) {
                                // If there is a total row, but not all three (open button, checkbox + comments), to set colspan in totalrow
                                let colspan;
                                if (!this.props.showOpenButton)
                                    colspan = 2
                                else colspan = 3
                                if (row.id.key === 'sum' ){
                                    return (
                                        <DataGridRow
                                        key={'row' + i}
                                        enableCheckboxes={false}
                                        getLatestComment={false}
                                        rowClicked={this.rowClicked}
                                        openClicked={this.openClicked}
                                        isSelected={this.isSelected}
                                        showOpenButton={false}
                                        showDecimals={this.props.showDecimals}
                                        row={row}
                                        rows={rows}
                                        index={i}
                                        isSum={this.props.isSum}
                                        isDiff={this.props.isDiff}
                                        columns={columns}
                                        focusedRow={this.state.focusedRow}
                                        sortDirection={this.state.sortDirection}
                                        sortColumn={this.state.sortColumn}
                                        disableRowSelection={true}
                                        disableCopyID={true }
                                        model={this.props.model}
                                        hastotalrow={true}
                                        colspan={colspan}
                                    /> 
                                    ) 
                                }
                                else {
                                    return (
                                    <DataGridRow
                                        key={'row' + i}
                                        enableCheckboxes={this.props.enableCheckboxes}
                                        getLatestComment={this.props.getLatestComment}
                                        rowClicked={this.rowClicked}
                                        openClicked={this.openClicked}
                                        isSelected={this.isSelected}
                                        showOpenButton={this.props.showOpenButton}
                                        showDecimals={this.props.showDecimals}
                                        row={row}
                                        rows={rows}
                                        index={i}
                                        isSum={this.props.isSum}
                                        isDiff={this.props.isDiff}
                                        columns={columns}
                                        focusedRow={this.state.focusedRow}
                                        sortDirection={this.state.sortDirection}
                                        sortColumn={this.state.sortColumn}
                                        disableRowSelection={(this.props.isDiff || this.props.isSum) && i === 0 ? true : this.props.disableRowSelection}
                                        disableCopyID={this.props.disableCopyID}
                                        model={this.props.model}
                                    /> 
                                    )
                                }}
                            else {
                                return (
                                <DataGridRow
                                    key={'row' + i}
                                    enableCheckboxes={this.props.enableCheckboxes}
                                    getLatestComment={this.props.getLatestComment}
                                    rowClicked={this.rowClicked}
                                    openClicked={this.openClicked}
                                    isSelected={this.isSelected}
                                    showOpenButton={this.props.showOpenButton}
                                    showDecimals={this.props.showDecimals}
                                    row={row}
                                    rows={rows}
                                    index={i}
                                    isSum={this.props.isSum}
                                    isDiff={this.props.isDiff}
                                    columns={columns}
                                    focusedRow={this.state.focusedRow}
                                    sortDirection={this.state.sortDirection}
                                    sortColumn={this.state.sortColumn}
                                    disableRowSelection={(this.props.isDiff || this.props.isSum) && i === 0 ? true : this.props.disableRowSelection}
                                    disableCopyID={this.props.disableCopyID}
                                    model={this.props.model}
                                    hastotalrow={false}

                                /> 
                                )
                            }
                         } 
                        )
                    }
                </tbody>
                <colgroup>
                    <RequirePermission perms={is.itemAccessor}>
                        {
                            this.props.showOpenButton ? <col className="datagrid-icon-cell" /> : null
                        }
                    </RequirePermission>
                    {
                        this.props.enableCheckboxes ? <col className="datagrid-icon-cell" /> : null
                    }
                    {
                        this.props.getLatestComment ? <col className="comment-icon-cell" /> : null
                    }
                    {
                        columns.map((c, i) => <col key={i} className={c.colgroupClassName ? c.colgroupClassName : ''} />)
                    }
                </colgroup>
            </BaseGridTable>
        )
    }
}

class DataGridRow extends React.Component {
    state = {}

    componentDidUpdate() {
        let id = this.props.row.id
        this.setState({ prevRowClass: this.getRowClass ? this.getRowClass(id) : null, prevComment: this.props.getLatestComment ? this.props.getLatestComment(id) : null })
    }

    componentDidMount() {
        let id = this.props.row.id
        this.setState({ prevRowClass: this.getRowClass ? this.getRowClass(id) : null, prevComment: this.props.getLatestComment ? this.props.getLatestComment(id) : null })
    }

    shouldComponentUpdate(nextProps, nextState) {
        let id = this.props.row.id
        return this.getRowClass(id) !== this.state.prevRowClass || //Row has been selected/viewed/added to my list
            nextProps.focusedRow !== this.props.focusedRow || //If the focused row has changed
            nextProps.showDecimals !== this.props.showDecimals ||  //If decimals has been toggled
            nextProps.showOpenButton !== this.props.showOpenButton || //open-button changed
            nextProps.rows.length !== this.props.rows.length || //If row count changed
            (nextProps.getLatestComment && nextProps.getLatestComment(id) !== null && this.state.prevComment === null) ||
            (nextProps.getLatestComment && nextProps.getLatestComment(id) !== null && this.state.prevComment !== null &&  nextProps.getLatestComment(id).comment !== this.state.prevComment.comment ) || //If comment added/deleted/changed
            JSON.stringify(nextProps.columns) !== JSON.stringify(this.props.columns) || //If columns added/removed/updated
            JSON.stringify(nextProps.row.data) !== JSON.stringify(this.props.row.data) || //If row data changed
            nextProps.sortColumn !== this.props.sortColumn || //If sorting changed
            nextProps.sortDirection !== this.props.sortDirection //If sorting changed
    }

    getRowClass = (id) => {
        let isSelected = this.props.isSelected && this.props.isSelected(id)
        let focused = equal(this.props.focusedRow, id)
        if (this.props.hastotalrow) {
            return 'abc-single-checklist-total-row'
        }
        if (focused && isSelected) { //Selected and viewed
            return 'abc-single-checklist-item-selected-viewed'
        } else if (focused && !isSelected) { //Viewed
            return 'abc-single-checklist-item-viewed'
        } else if (!focused && isSelected) { //Selected
            return 'abc-single-checklist-item-selected'
        }
        return ''
    }

    getLatestCommentComponent(id) {
        let comment = this.props.getLatestComment(id)
        if (comment) {
            return <div style={{whiteSpace: "nowrap"}}><span title={comment.comment}><span className="fa fa-comment abc-item-has-comment" />
                <span className="small-comment-number">{comment.count}</span>
            </span></div>
        }
        return null
    }

    onMouseDown = e => {
        // avoid the whole table gets highlighted when you use shift selection
        if (e.shiftKey) e.preventDefault()
    }

    render() {
        let r = this.props.row

        return (
            <tr className={`${this.props.disableRowSelection ? '' : 'abc-click'} all-white-space-normal ${this.getRowClass(r.id)}`} onClick={e => this.props.rowClicked(r.id, e, undefined, this.props.index, this.props.disableRowSelection)} onMouseDown={this.onMouseDown}>
                {
                    !this.props.showOpenButton && this.props.hastotalrow && !this.props.enableCheckboxes && this.props.colspan === 2 ? <td colSpan="2 "className="datagrid-icon-cell center" style={{borderRightWidth:0, fontWeight: "bold"}} >  Total</td> 
                    : 
                    !this.props.showOpenButton && this.props.hastotalrow && !this.props.enableCheckboxes && this.props.colspan === 3 ? <td colSpan="3 "className="datagrid-icon-cell center" style={{borderRightWidth:0, fontWeight: "bold"}} >  Total</td> 
                    :
                    !this.props.showOpenButton ? null
                    :
                    <>
                        {this.props.isDiff && this.props.index === 0 ? <td className={`sticky-td top-29px`}></td> :
                            <td className="datagrid-icon-cell center open-datagrid-cell" onClick={e => this.props.openClicked(r.id, e, this.props.index, false)}>
                                <span className="fa fa-external-link abc-click gray-icon" title="See details" />
                            </td>}
                    </>
                }
                {
                    !this.props.enableCheckboxes ? null :
                        <td className="datagrid-icon-cell center checkbox-datagrid-cell" onClick={e => this.props.rowClicked(r.id, e, true, this.props.index)}>
                            <input type="checkbox" className="vertical-align-middle" checked={this.props.isSelected(r.id)} onChange={e => this.props.rowClicked(r.id, e, true, this.props.index)} />
                        </td>
                }
                {
                    this.props.getLatestComment && !this.props.showOpenButton ?
                        <td className="comment-icon-cell center">
                            {
                                this.getLatestCommentComponent(r.id)
                            }
                        </td>
                        : (this.props.getLatestComment && this.props.showOpenButton ? 
                            <td className="comment-icon-cell center" onClick={e => this.props.openClicked(r.id, e, this.props.index, true)}>
                            {
                                this.getLatestCommentComponent(r.id)
                            }
                            </td> : null)
                }
                {
                    this.props.columns.map((col, j) =>
                        <DataGridCell
                            model={this.props.model}
                            key={'cell' + this.props.index + '-' + j}
                            column={col}
                            row={r}
                            showDecimals={this.props.showDecimals}
                            sortDirection={this.props.sortDirection}
                            sortColumn={this.props.sortColumn}
                            columns={this.props.columns}
                            disableCopyID={this.props.disableCopyID}
                            index={this.props.index}
                            isSum={this.props.isSum}
                            isDiff={this.props.isDiff}
                        />

                    )
                }
            </tr>
        )
    }
}

class DataGridCell extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.showDecimals !== this.props.showDecimals && this.props.column.type === 'decimal') ||
            nextProps.sortColumn !== this.props.sortColumn ||
            nextProps.sortDirection !== this.props.sortDirection ||
            !equal(nextProps.column, this.props.column) ||
            nextProps.columns.length !== this.props.columns.length ||
            nextProps.row.data[nextProps.column.index] !== this.props.row.data[this.props.column.index]
    }

    copyToClipboard(e, value) {
        e.stopPropagation()
        copyToClipboard(value)
        notifySuccess(`"${value}" was copied to clipboard`)
    }

    render() {
        let r = this.props.row
        let row = r.data
        let model = this.props.model
        let col = this.props.column
        let value = formatValue(col.type === 'decimal' || typeof row[col.index] === "number", row[col.index], this.props.showDecimals || col.showDecimals)
        
        let indicator = null

        const lookupColor = (colVal, categories) => {
            for (let i = 0; i < categories.length; i++) {
                if (categories[i].name === colVal) {
                    return `rgb(${categories[i].color.r}, ${categories[i].color.g}, ${categories[i].color.b})`
                }
            }
            return 'var(--light-almond)'
        }

        if (col.name === model?.categorization_name) {
            let color = lookupColor(value, model.categorization)
            indicator = <div style={{display: 'inline-block', marginRight: 10, width: 5, height: 5, padding: 5, backgroundColor: color}}></div>
        }


        return (
            <td className={`${col.type === 'decimal' || col.type === 'date' || col.type === 'calculated' || col.type === 'id' ? 'abc-number' : ''} ${this.props.index === 0 && this.props.isSum ? "sticky-td" : this.props.index === 0 && this.props.isDiff ? "sticky-td top-29px abc-number" : ""} ${col.columnClassName ? col.columnClassName : ''}`} title={value}>
                {
                    col.type === 'id' && !this.props.disableCopyID ? (
                        <div className="id-column">
                            <div className={"word-wrap-with-share-icon"}>
                                <WordWrap>
                                    {value}
                                </WordWrap>
                            </div>
                            <div className="float-right abc-click gray-icon copy-id-icon" onClick={e => this.copyToClipboard(e, value)}>
                                &nbsp;
                                <span className="fa fa-copy" title="Copy id to clipboard" />
                            </div>
                        </div>
                    ) : <>
                        {this.props.isDiff && this.props.index === 0 ?
                            <WordWrap>{col.wrapper ? "" : value}</WordWrap>
                            : <WordWrap>{indicator}{col.wrapper ? col.wrapper(value, row[col.index]) : value}</WordWrap>
                        }

                    </>

                }
            </td>
        )
    }
}

export default DataGrid
