import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import ShowIf from '../components/Generic/ShowIf'
import BgColor from '../components/Generic/BgColor'
import RequirePermission from '../components/RequirePermission'
// @ts-ignore
import Select from '../components/Generic/CustomSelect'
import { copyReport, createGroup, deleteGroup, editGroupName, jumpToCompose, moveGroup } from '../actions/ReportActions'
import { is, isReportCreate } from '../helpers/PermissionHelpers'
import { getFullName } from '../helpers/UserHelpers'
import { Favourite, QueryCriterion, Group, LibraryGroup, LibraryReport, User, LoginSession, Report, OrgInfo, OrganizationSubscriptionSettings } from '../types/transfertypes'
import { connect } from 'react-redux'
import Drawer from '../components/Animation/Drawer'
import { removeColumns, toggleFavourite } from '../actions/ReportActions'
import { fromNow } from '../helpers/TimeHelpers'
import { getDataDescriptionDialogDescription } from '../helpers/ReportHelpers'
//@ts-ignore
import { Link } from 'react-router-dom'

/* CSS */
import '../css/home.css'
import '../css/sidebar.css'
import '../css/dimensions.css'
import '../css/misc.css'
import '../css/margin.css'
import '../css/library.css'
import { setPageTitle } from '../helpers/DocumentHelpers'
import { assignReportsToOwner, changeMultipleReportStatus, deleteMultipleReports, getFavouriteReportInfo, getLibraryAdminInfo, getLibraryInfo, moveReportsToGroup } from '../actions/InfoActions'
import { getUsers } from '../actions/SystemActions'
import { getUserInfo } from '../actions/UserActions'
import { getBucketUsers } from '../actions/BucketActions'
import LibraryItem from '../components/Report/LibraryItem'
import FixReportDialog from '../components/Dialogs/FixReportDialog'
import SortableList, { DragHandle } from '../components/Generic/SortableList'
import { isReportAdmin } from '../helpers/PermissionHelpers'
import { notifyFailure } from '../helpers/NotificationManager'
import SaveDialog from '../components/Dialogs/SaveDialog'
import WarningAcceptDialog from '../components/Dialogs/WarningAcceptDialog'
import SaveWithWarningDialog from '../components/Dialogs/SaveWithWarningDialog'
import { getReportUpdateData } from '../helpers/ReportHelpers'
import MultiSelectBar, { MultiSelectButtonType, MultiSelectOption } from '../components/MultiSelectBar'
import { SingleKeyMap } from '../helpers/Collections'
import StandardTable from '../components/Generic/StandardTable'
import SortArrow from '../components/Generic/SortArrow'
import ResponsiveToggle from '../components/Generic/ResponsiveToggle'
import HomeIcon from '../components/icons/HomeIcon'
import ReportDescription from '../components/ReportDescription'
import ReactTooltip from 'react-tooltip'
import CreateReportDialog from '../components/Report/CreateReportDialog'
import { ReportTemplateGroupWrapper } from '../types/types'
import { getReportTemplates } from '../actions/TemplateActions'
import { FaEnvelope } from 'react-icons/fa'
import { createMailBundle, deleteMailBundle, getAllPlans, getOrgMailBundles, updateMailBundle } from '../actions/MailBundleActions'
import InsightMailSignupModal from '../components/Report/InsightMailSignupModal'
import { MailBundleWithRelations, newMailBundle, Plan } from '../types/reportingtypes'
import { getOrganization, getOrganizationSubscriptionSettings } from '../actions/OrganizationActions'

interface LibraryProps {
    dispatch: any,
    info: LibraryGroup[],
    adminInfo: LibraryGroup[] | null,
    users: User[],
    user: LoginSession,
    favourites: Favourite[],
    bucketUsers: SingleKeyMap<User[]>,
    history: any,
    templates?: ReportTemplateGroupWrapper,
    mailbundles: MailBundleWithRelations[],
    plans: Plan[]
    orgInfo: OrgInfo
    subscriptionSettings: OrganizationSubscriptionSettings
}

let mapStateToProps = (state: any, ownProps: LibraryProps) => {
    return {
        info: state.Info.libraryInfo,
        adminInfo: state.Info.libraryAdminInfo,
        users: state.System.users,
        // reportsInDashboards: state.Report.reportsInDashboards,
        user: state.User.info,
        favourites: state.Report.favourites.get("data", []),
        bucketUsers: state.Bucket.bucketUsers,
        templates: state.Report.reportTemplateGroups,
        mailbundles: state.Reporting.mailBundles,
        plans: state.Reporting.plans,
        orgInfo: state.Organization.info,
        subscriptionSettings: state.Organization.subscriptionSettings
    }
}
//@ts-ignore
let Library = ({templates = {buckets: [], groups: [], enabled_groups: [], enabled: false} , ...props}: LibraryProps) => {
    const [editMode, setEditMode] = useState(false)
    const [newGroup, setNewGroup] = useState(false)
    const [newGroupName, setNewGroupName] = useState('')
    const [searchString, setSearchString] = useState('')
    const [ownerFilter, setOwnerFilter] = useState<number | null>(null)
    const [bucketFilter, setBucketFilter] = useState<string | null>(null)
    const [favFilter, setFavFilter] = useState<boolean>(false)
    
    const [showFix, setShowFix] = useState(false)
    const [reportToFix, setReportToFix] = useState<LibraryReport | null>(null)

    const [reportsToEdit, setReportsToEdit] = useState<LibraryReport[]>([])
    const [selectedReports, setSelectedReports] = useState<LibraryReport[]>([])
    const [moveToGroup, setMoveToGroup] = useState<Group | null>(null)
    const [newOwner, setNewOwner] = useState<User | null>(null)

    const [groupToEdit, setGroupToEdit] = useState<LibraryGroup | null>(null)
    const [tmpGroupName, setTmpGroupName] = useState('')
    const [tmpReportName, setTmpReportName] = useState('')
    const [tmpReportDescription, setTmpReportDescription] = useState('')
    const [tmpFav, setTmpFav] = useState(false)

    const changeGroupNameRef = useRef<any>()
    const changeGroupDialogRef = useRef<any>()
    const changeOwnerDialogRef = useRef<any>()
    const changePublicDialogRef = useRef<any>()
    const copyReportDialogRef = useRef<any>()

    const confirmGroupDeleteDialogRef = useRef<any>()
    const confirmReportsDeleteDialogRef = useRef<any>()

    const [bucketUsersFetched, setBucketUsersFetched] = useState<any>([])

    // const openLeftGroups = useRef<Set<number>>(new Set())
    const [openLeftGroups, setOpenLeftGroups] = useState<Set<number>>(new Set())
    const [openPrivateGroups, setOpenPrivateGroups] = useState<Set<number>>(new Set())
    const [privateVisible, setPrivateVisible] = useState<boolean>(false)
    const [isTableView, setIsTableView] = useState<boolean>(true)

    const [showCreateReport, setShowCreateReport] = useState(false)

    const [adminInfoLoaded, setAdminInfoLoaded] = useState<boolean>(false)

    // On Mount
    useEffect(() => {
        setPageTitle("Insights")

        let { dispatch } = props
        dispatch(getLibraryInfo())
        dispatch(getUsers())
        dispatch(getUserInfo())
        dispatch(getFavouriteReportInfo())
        dispatch(getAllPlans())
    }, [])

    useEffect(() => {
        if(!!props.user?.organization){
            props.dispatch(getOrgMailBundles(props.user.organization))
            props.dispatch(getOrganization(props.user.organization))
            props.dispatch(getOrganizationSubscriptionSettings(props.user.organization))
        }
    }, [props.user])

    useEffect(() =>{
        let fetchedBuckets = bucketUsersFetched
        if (props.adminInfo != null){
            props.adminInfo.forEach(group => {
                group.reports?.forEach(elem => {
                    if(!fetchedBuckets.includes(elem.report.bucket)) {
                        if(!props.bucketUsers.contains(elem.report.bucket))
                            props.dispatch(getBucketUsers(elem.report.bucket))
                        fetchedBuckets.push(elem.report.bucket)
                        setBucketUsersFetched(fetchedBuckets)
                    }
                })
            })
        }

        if (props.info != null){
            props.info.forEach(group => {
                group.reports?.forEach(elem => {
                    if(!fetchedBuckets.includes(elem.report.bucket)) {
                        if(!props.bucketUsers.contains(elem.report.bucket))
                            props.dispatch(getBucketUsers(elem.report.bucket))
                        fetchedBuckets.push(elem.report.bucket)
                        setBucketUsersFetched(fetchedBuckets)
                    }
                })
            })
        }
    }, [props.adminInfo, props.info])

    // Check if user is admin, requires that user data has been loaded
    useEffect(() => {
        if (isReportAdmin(props.user.access) && !adminInfoLoaded) {
            setAdminInfoLoaded(true)
            props.dispatch(getLibraryAdminInfo())
        }
        if (isReportCreate(props.user.access) && !templates.fetched) {
            props.dispatch(getReportTemplates())
        }
    }, [props.user])

    let saveGroup = () => {
        const { dispatch } = props
        dispatch(createGroup({ name: newGroupName }))
        setNewGroup(false)
        setNewGroupName('')
    }

    let getOwnerValue = () => {
        if (ownerFilter == null){
            return null
        }
        return {value: ownerFilter, label: getFullName(props.users, ownerFilter)}
    }

    let getBucketValue = () => {
        if (bucketFilter == null){
            return null
        }
        return {value: bucketFilter, label: bucketFilter}
    }

    let onOwnerFilterChange = (val: {label: string, value: number}, e: { action: string }) => {
        if (e.action == "clear"){
            setOwnerFilter(null)
        } else if (e.action == "select-option") {
            setOwnerFilter(val.value)
        }
    }

    let onBucketFilterChange = (val: {label: string, value: string}, e: { action: string }) => {
        if (e.action == "clear"){
            setBucketFilter(null)
        } else if (e.action == "select-option") {
            setBucketFilter(val.value)
        }
    }

    let deleteLibraryGroup = () => {
        if (groupToEdit == null) return

        const { dispatch } = props
        dispatch(deleteGroup(groupToEdit.group.group_id))
        setGroupToEdit(null)
    }

    let reportFilterPredicate = (report: LibraryReport) => {
        if (ownerFilter != null) {
            if (report.report.user_id != ownerFilter) {
                return false
            }
        }
        if (bucketFilter != null) {
            if (report.info.name != bucketFilter) {
                return false
            }
        }
        if (favFilter){
            if (!report.isFav)
                return false
        }

        return report.report.name.toLowerCase().includes(searchString.toLowerCase())
    }

    let manualFixReport = (report: LibraryReport) => {
        setReportToFix(report)
        setShowFix(true)
    }

    let getLibraryGroup = (group: LibraryGroup, i: number) => {
        if((group.reports != null && group.reports.length > 0) || editMode) {
            return <div>
                <LibraryGroupElement subscriptionSettings={props.subscriptionSettings ?? {}} self_subscription_enabled={props.orgInfo?.self_subscription_enabled} mailbundles={props.mailbundles} plans={props.plans} key={`${i}-${group.group.group_id}`} isTableView={isTableView} dispatch={props.dispatch} group={group} users={props.users} user={props.user} manualFixReport={manualFixReport} drawerClass='library-drawer-grid' draggable editMode={editMode} dropFuncs={dropFuncs} deleteGroup={promptDeleteGroup} editGroupName={onEditGroupName} toggleSelect={toggleSelect} selectedReports={selectedReports} openGroups={openLeftGroups} setOpenGroups={setOpenLeftGroups} setBucketFilter={setBucketFilter} setOwnerFilter={setOwnerFilter} setSelect={setSelect}/>
            </div>
        }
        else {
            return <div></div>
        }
    }

    let moveGroupFunc = (oldIndex: number, newIndex: number, group: LibraryGroup) => {
        const { dispatch } = props
        dispatch(moveGroup(group.group.group_id, { direction: newIndex - oldIndex > 0 ? "down" : "up", places: Math.abs(newIndex - oldIndex) }))
    }

    let onEditGroupName = (group: LibraryGroup) => {
        setGroupToEdit(group)
        setTmpGroupName(group.group.name)
        changeGroupNameRef.current.show()
    }

    // Functions for the dropdowns
    let onEdit = (report: LibraryReport) => {
        props.dispatch(jumpToCompose(report.report.report_id));
        window.location.hash = `/report/${report.report.report_id}`
    }

    let onMoveTo = (report: LibraryReport, currentGroup: Group) => {
        setReportsToEdit([report])
        setMoveToGroup(currentGroup)
        changeGroupDialogRef.current.show()

    }

    let onChangeOwner = (report: LibraryReport) => {
        setReportsToEdit([report])
        let selUsers =  getSelectionUsers([report])
        let user = selUsers.find((val) => val.id == props.user.user_id)
        user = user ?? selUsers[0]
        setNewOwner(user)
        changeOwnerDialogRef.current.show()
    }

    let onTogglePrivate = (report: LibraryReport) => {
        setReportsToEdit([report])
        setMoveToGroup(unsortedGroup.group)
        changePublicDialogRef.current.show()
    }

    let onCopy = (report: LibraryReport) => {
        setReportsToEdit([report])
        setTmpReportName(report.report.name + " Copy")
        setTmpReportDescription(report.report.description)
        copyReportDialogRef.current.show()
    }

    let onDelete = (report: LibraryReport) => {
        setReportsToEdit([report])
        confirmReportsDeleteDialogRef.current.show()
    }


    let saveMoveGroup = () => {
        if (moveToGroup == null || reportsToEdit.length == 0) return
        let reportIds: number[] = []
        let foundPrivateReports = false

        reportsToEdit.forEach(r => {
            if(r.report.is_public){
                reportIds.push(r.report.report_id)
            }
            else {
                foundPrivateReports = true
            }
        })

        if(reportIds.length > 0){
            props.dispatch(moveReportsToGroup(moveToGroup.group_id, reportIds))
        }

        setSelectedReports([])
        setReportsToEdit([])
        setMoveToGroup(null)

        if(foundPrivateReports){
            notifyFailure("Private Insights can't be moved",null)
        }
    }

    let saveGroupName = () => {
        if (groupToEdit == null || tmpGroupName == '') return
        props.dispatch(editGroupName(groupToEdit.group.group_id, {name: tmpGroupName}))
        setGroupToEdit(null)
        setTmpGroupName('')
    }

    let saveNewOwner = () => {
        if (newOwner == null || reportsToEdit.length == 0) return
        let reportIds: number[] = []
        reportsToEdit.forEach(r => {
            reportIds.push(r.report.report_id)
        })
        props.dispatch(assignReportsToOwner(newOwner.id, reportIds))

        setReportsToEdit([])
        setNewOwner(null)
    }

    let saveDeleteReports = () => {
        if (reportsToEdit.length == 0) return
        let reportIds: number[] = []
        reportsToEdit.forEach(r => {
            reportIds.push(r.report.report_id)
        })
        props.dispatch(deleteMultipleReports(reportIds))
        if (reportsToEdit.length > 0) { // If it was a multi-delete
            setSelectedReports([])
        }
        setReportsToEdit([])
    }

    let saveReportStatus = () => {
        if (reportsToEdit.length == 0 || moveToGroup == null) return
        let is_public = reportsToEdit.some(r => r.report.is_public)
        let reportIds: number[] = []
        reportsToEdit.forEach(r => {
            reportIds.push(r.report.report_id)
        })
        props.dispatch(changeMultipleReportStatus(!is_public, moveToGroup.group_id, reportIds))
        setReportsToEdit([])
        setMoveToGroup(null)
    }

    let saveCopyReport = () => {
        if (reportsToEdit.length != 1 || tmpReportName == '') return
        let changes = {
            name: tmpReportName,
            addToFavorites: tmpFav,
            is_public: false,
            description: tmpReportDescription,
            user_id: props.user.user_id,
            group_id: 0
        }
        let report = getReportUpdateData(changes, reportsToEdit[0])
        props.dispatch(copyReport(report, false))
    }

    let promptDeleteGroup = (group: LibraryGroup) => {
        setGroupToEdit(group)
        confirmGroupDeleteDialogRef.current.show()
    }

    let toggleSelect = (report: LibraryReport) => {
        if (!selectedReports.some(v => v.report.report_id == report.report.report_id)){
            let arr = [...selectedReports]
            arr.push(report)
            setSelectedReports(arr)
        } else {
            let arr = selectedReports.filter(v => v.report.report_id != report.report.report_id)
            setSelectedReports(arr)
        }
    }

    let setSelect = (value: boolean, ...reports: LibraryReport[]) => {
        if (value) {
            let arr = reports.filter(v => !selectedReports.some(v2 => v.report.report_id === v2.report.report_id))
            let temp = [...selectedReports]
            temp.push(...arr)
            setSelectedReports(temp)
        }
        else {
            let arr = selectedReports.filter(v => !reports.some(v2 => v.report.report_id === v2.report.report_id))
            setSelectedReports(arr)
        }
    }

    let onMultiChangeOwner = () => {
        setReportsToEdit(selectedReports)
        let selUsers =  getSelectionUsers(selectedReports)
        let user = selUsers.find((val) => val.id == props.user.user_id)
        user = user ?? selUsers[0]
        setNewOwner(user)
        changeOwnerDialogRef.current.show()
    }

    let onMultiMakePublic = () => {
        setReportsToEdit(selectedReports)
        setMoveToGroup(unsortedGroup.group)
        changePublicDialogRef.current.show()
    }

    let onMultiMoveTo = () => {
        setReportsToEdit(selectedReports)
        setMoveToGroup(unsortedGroup.group)
        changeGroupDialogRef.current.show()
    }

    let onMultiDelete = () => {
        setReportsToEdit(selectedReports)
        confirmReportsDeleteDialogRef.current.show()
    }

    if (props.info == null || props.users == null || props.user == null) {
        return (<div></div>)
    }

    let owners = new Set()
    let buckets = new Set()

    if (props.info != null){
        props.info.forEach((group: LibraryGroup) => {
            if (group.reports != null) {
                group.reports.forEach((report) => {
                    owners.add(report.report.user_id)
                    buckets.add(report.info.name)
                })
            }
        })
    }
    let ownerOptions = Array.from(owners).map((owner: any) => {
        return {value: owner, label: getFullName(props.users, owner)}
    }).sort((a, b) => a.label.localeCompare(b.label));
    let bucketOptions = Array.from(buckets).map((bucket: any) => {
        return {value: bucket, label: bucket}
    }).sort((a, b) => a.label.localeCompare(b.label));


    // let favGroup = Object.assign({}, props.info.find((group) => group.group.group_id == -2))
    let privateGroup = Object.assign({}, props.info.find((group) => group.group.group_id == -1))
    let unsortedGroup = Object.assign({}, props.info.find((group) => group.group.group_id == 0))

    let otherGroups: LibraryGroup[] = []
    props.info.filter((group) => group.group.group_id != -2 && group.group.group_id != -1 && group.group.group_id != 0).forEach(group => {
        otherGroups.push(Object.assign({}, group))
    })

    let totalReports = 0
    let shownReports = 0

    let sortPredicate = (a: LibraryReport, b: LibraryReport) => a.report.name.localeCompare(b.report.name)

    // if (favGroup?.reports != null) {
    //     totalReports += favGroup.reports.length
    //     favGroup.reports = favGroup.reports.filter((report) => reportFilterPredicate(report))
    //     shownReports += favGroup.reports.length
    //     favGroup.reports.sort(sortPredicate)
    // }
    if (privateGroup?.reports != null) {
        totalReports += privateGroup.reports.length
        privateGroup.reports = privateGroup.reports.filter((report) => reportFilterPredicate(report))
        shownReports += privateGroup.reports.length
        privateGroup.reports.sort(sortPredicate)
    }
    if (unsortedGroup?.reports != null) {
        totalReports += unsortedGroup.reports.length
        unsortedGroup.reports = unsortedGroup.reports.filter((report) => reportFilterPredicate(report))
        shownReports += unsortedGroup.reports.length
        unsortedGroup.reports.sort(sortPredicate)
    }
    otherGroups.forEach(group => {
        if (group.reports != null) {
            totalReports += group.reports.length
            group.reports = group.reports.filter((report) => reportFilterPredicate(report))
            shownReports += group.reports.length
            group.reports.sort(sortPredicate)
        }
    })
    otherGroups.sort((a, b) => a.group.position - b.group.position)

    const isConsultant = (userID:number) => {
        const user = props.users.find(u => u.id === userID);
        return user?.is_consultant
    }
    
    
    let adminGroups: LibraryGroup[] = []
    if (props.adminInfo != null) {
        const loggedInAsConsultant = props.user.is_consultant_login;
        props.adminInfo
            .filter(group =>  { return loggedInAsConsultant || (!loggedInAsConsultant && !isConsultant(group.group.group_id))})
            .forEach(group => {
                let tmpGroup = Object.assign({}, group)
                if (tmpGroup.reports != null) {
                    totalReports += tmpGroup.reports.length
                    tmpGroup.reports = tmpGroup.reports.filter((report) => reportFilterPredicate(report))
                    shownReports += tmpGroup.reports.length
                    tmpGroup.reports.sort(sortPredicate)
                }
                tmpGroup.group.name = `${getFullName(props.users, tmpGroup.group.group_id)}'s private Insights`
                adminGroups.push(tmpGroup)
            })
    }
    adminGroups.sort((a, b) => a.group.position - b.group.position)

    let otherGroupsForDialogs: LibraryGroup[] = otherGroups //e.g. not filtered

    if (searchString != "" || ownerFilter != null || bucketFilter != null || favFilter) {
        otherGroups = otherGroups.filter(g => {
            if (g.reports == null) return false
            return g.reports.length > 0
        })

        adminGroups = adminGroups.filter(g => {
            if (g.reports == null) return false
            return g.reports.length > 0
        })
        
    }

    let dropFuncs: LibraryDropdownFunctions = {
        onEdit,
        onMoveTo,
        onChangeOwner,
        onTogglePrivate,
        onCopy,
        onDelete,
    }

    let isAnySelectedPublic = () => {
        return selectedReports.some(r => r.report.is_public)
    }

    let multiOptions: MultiSelectOption[] = [
        {
            label: <span>Make public</span>,
            onClick: onMultiMakePublic,
            disable: isAnySelectedPublic()
        },
        {
            label: <span>Move to group</span>,
            onClick: onMultiMoveTo
        },
        {
            label: <span>Change owner</span>,
            onClick: onMultiChangeOwner
        },
        {
            label: <span>Delete</span>,
            onClick: onMultiDelete,
            type: MultiSelectButtonType.WARNING
        }
    ]

    let clearLeftGroups = () => {
        setOpenLeftGroups(new Set())
    }
    let clearPrivateGroups = () => {
        setOpenPrivateGroups(new Set())
    }
    let togglePrivateVisible = () => {
        setPrivateVisible(!privateVisible)
    }

    let getSelectionUsers = (reports: LibraryReport[]) => {
        let selectionUsers = props.users;
    
        if(reports.length > 0){
            let bucketsInSelection = new Set<string>()
            reports.forEach(elem => {
                if(!bucketsInSelection.has(elem.report.bucket)){
                    bucketsInSelection.add(elem.report.bucket)
                }
            })
            let bucketUsersIntersection = selectionUsers
            bucketsInSelection.forEach( bucketId => {
                    bucketUsersIntersection = props.bucketUsers.get(bucketId, []).filter((a: User) => bucketUsersIntersection.some(b=> b.user_id === a.user_id))
                }
            )
            selectionUsers = bucketUsersIntersection
        }
        return selectionUsers
    }

    let selectionUsers = props.users;
    if(reportsToEdit.length > 0){
        selectionUsers = getSelectionUsers(reportsToEdit)
    }

    if(selectedReports.length > 0){
        selectionUsers = getSelectionUsers(selectedReports)
    }

    return (
        <BgColor bgClass="library-background">
                <div className={"home-settings "} >
                    <div className={""}>
                        <div className='library-title'>
                            <h1 className={"pt-3 margin-bottom-5px"}>Insights</h1>
                        <RequirePermission perms={is.reportCreator}>
                            {
                                templates.enabled ?
                                    <button onClick={() => setShowCreateReport(true)} className="btn btn-primary btn-sm"><i className="fa fa-plus"></i> New Insight</button>
                                    : 

                                    <Link to="/newreport">
                                        <button className="btn btn-primary btn-sm"><i className="fa fa-plus"></i> New Insight</button>
                                    </Link>
                            }
                        </RequirePermission>
                        </div>
                        <div className='library-toolbar'>
                            <div className='library-filters'>
                                <Select 
                                    options={ownerOptions}
                                    isClearable
                                    onChange={onOwnerFilterChange}
                                    className={"fixed-slicer-select margin-left-10"}
                                    classNamePrefix="fixed-slicer-select"
                                    placeholder="Owner"
                                    value={getOwnerValue()}
                                />
                                <Select 
                                    options={bucketOptions}
                                    isClearable
                                    onChange={onBucketFilterChange}
                                    className={"fixed-slicer-select margin-left-10"}
                                    classNamePrefix="fixed-slicer-select"
                                    placeholder="Data source"
                                    value={getBucketValue()}
                                />
                            <div className="library-checkbox-holder">
                                <input type="checkbox" className="vertical-align-middle" onChange={(e) => setFavFilter(e.target.checked)} />
                                <span> Show favourites only</span>
                            </div>

                                
                                
                                
                            </div>
                            <div className='library-search'>
                            
                            


                            <div  style={{whiteSpace: "nowrap", display: "flex", alignItems:"center"}}>
                                <span className="vertical-align-middle" style={{marginRight: 8}}>{`Showing ${shownReports} of ${totalReports}`}</span>
                            
                            </div>
                            <div className="input-group margin-top-0" id="searchBarContainer">
                                <input id="searchIdInput" className={`form-control form-control-sm zero-border-right ${searchString ? 'alert-warning' : ''}`}
                                    placeholder={`Search`} onChange={(e) => setSearchString(e.target.value)} value={searchString} />
                                <span title="Search beginning" className={`input-group-append ${searchString ? 'alert-warning' : ''}`}>
                                    <button className="btn btn-sm btn-default" type="button">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </span>
                                <div className="btn-group margin-left-5px" role="group">
                                <button type="button" className={`btn btn-sm btn-default ${isTableView ? 'active': ''}`} title="List View" onClick={() => setIsTableView(true)}> <i className="fa fa-list"></i> </button>
                                <button type="button" className={`btn btn-sm btn-default ${isTableView ? '': 'active'}`} title="Box View" onClick={() => setIsTableView(false)}> <i className="fa fa-th-large"></i></button>
                                </div>
                                <div style={{marginLeft: 5}}>
                                    {
                                    editMode ?
                                        <React.Fragment>
                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => {
                                                setEditMode(false)
                                                setNewGroup(false)
                                                setNewGroupName('')
                                            } } >Edit mode: ON <i className="fa fa-cog"></i></button>
                                        </React.Fragment>
                                        :
                                        <RequirePermission perms={is.reportAdmin}>
                                            <button className={"btn btn-default btn-sm"} onClick={() => setEditMode(true)} > <i className="fa fa-cog"></i> </button>
                                        </RequirePermission>
                                    }
                                </div>
                            </div>
                           
                            </div>
                        </div>
                        <ShowIf if={editMode}>
                            <div className="library-edit-bar">
                                <div className="width-100-p">
                                    <button onClick={() => setNewGroup(true)} className="btn btn-primary btn-sm "><i className="fa fa-plus"></i> New group</button>
                                    <div className="danger-fix alert-danger form-control-sm inline-block margin-right-15px float-right">
                                         Warning! You are editing a shared view! The changes will affect all of your colleagues <i className="fa fa-exclamation-triangle"></i>
                                    </div>
                                    {
                                        newGroup ?
                                            <React.Fragment>
                                                <div className="form-group margin-top-10px">
                                                    <label>New group name: </label>
                                                    <input type="text" className="form-control width-400px inline-block margin-left-10px form-control-sm" onChange={(e) => setNewGroupName(e.target.value) } />
                                                    <div className="btn-group margin-left-5px inline-block">
                                                        <button className="btn btn-sm btn-default inline-block" onClick={() => {
                                                            setNewGroup(false)
                                                            setNewGroupName('')
                                                        }}
                                                            title="Cancel"><span className="fa fa-times red"></span>
                                                        </button>
                                                        <button className="btn btn-sm btn-default inline-block" title="Save group" onClick={() => saveGroup()}>
                                                            <span className="fa fa-check green"></span>
                                                        </button>
                                                    </div>
                                                    <div className="danger-fix alert-warning form-control-sm inline-block margin-right-15px float-right">
                                                        NB: Empty groups will not be visible when exiting edit mode <i className="fa fa-exclamation-triangle"></i>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            : null
                                    }
                                </div>
                            </div>
                        </ShowIf>
                        {
                            editMode ?
                                <hr className="hr-color margin-top-10px margin-bottom-10px" />
                                : null
                        }

                        <MultiSelectBar type='Insight' selected={selectedReports} options={multiOptions} onDeselectClick={() => {setSelectedReports([])}} />

                        <div className="mt-2"></div>
                        <div className='flex-space-20'>
                            <div className='library-all-reports-container'>
                                <div className='library-all-reports'>
                                    <div style={{borderBottom: "1px solid #d9d9d9"}}>
                                        <h2 className='library-reports-title'>All Insights {openLeftGroups.size > 0 ? <span><span className='library-inline-divider'>|</span> <span onClick={clearLeftGroups} className='library-collapse abc-click'>Collapse all</span></span> : null}</h2>
                                    </div>
                                    <div className='library-all-reports-list'>
                                        <SortableList items={otherGroups} getItem={(g: LibraryGroup, i: number) => getLibraryGroup(g, i)} onSortStart={() => {}} onSortEnd={(o: number, n: number) => {moveGroupFunc(o, n, otherGroups[o])}} />
                                        {
                                            unsortedGroup?.reports != null && unsortedGroup?.reports.length > 0 ?
                                                <LibraryGroupElement subscriptionSettings={props.subscriptionSettings ?? {}} self_subscription_enabled={props.orgInfo?.self_subscription_enabled} mailbundles={props.mailbundles} plans={props.plans} isTableView={isTableView}  dispatch={props.dispatch}  group={unsortedGroup} users={props.users}  user={props.user} manualFixReport={manualFixReport} drawerClass='library-drawer-grid' editMode={editMode} dropFuncs={dropFuncs} deleteGroup={promptDeleteGroup} editGroupName={onEditGroupName}  toggleSelect={toggleSelect} selectedReports={selectedReports} openGroups={openLeftGroups} setOpenGroups={setOpenLeftGroups} setBucketFilter={setBucketFilter} setOwnerFilter={setOwnerFilter} setSelect={setSelect}/>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className='library-all-reports'>
                                    <div style={privateVisible ? {borderBottom: "1px solid #d9d9d9"} : {height: 21}}>
                                        <h2 className='library-reports-title' style={{fontSize:"14px"}}><span className='abc-click' onClick={togglePrivateVisible}>Private Insights 
                                        
                                        <i style={{fontSize:"smaller"}} className={`margin-left-10 fa ${privateVisible ? 'fa-chevron-down' :'fa-chevron-right'} `}></i>
                                        
                                        </span>
                                        
                                        {openPrivateGroups.size > 0 && privateVisible ? <span><span className='library-inline-divider'></span> <span onClick={clearPrivateGroups} className='library-collapse abc-click'>Collapse all</span></span> : null}
                                        
                                        </h2>
                                    </div>
                                    <ShowIf if={privateVisible}>
                                    <div className='library-all-reports-list'>
                                    <LibraryGroupElement subscriptionSettings={props.subscriptionSettings ?? {}} self_subscription_enabled={props.orgInfo?.self_subscription_enabled} isTableView={isTableView}  dispatch={props.dispatch} group={privateGroup} users={props.users}  user={props.user}  drawerClass='library-drawer-grid' manualFixReport={manualFixReport} editMode={editMode} dropFuncs={dropFuncs} deleteGroup={promptDeleteGroup} editGroupName={onEditGroupName}  toggleSelect={toggleSelect} selectedReports={selectedReports} openGroups={openPrivateGroups} setOpenGroups={setOpenPrivateGroups} setBucketFilter={setBucketFilter} setOwnerFilter={setOwnerFilter} setSelect={setSelect} />
                                    {adminGroups.map((group, index) => {
                                        return (
                                            <LibraryGroupElement subscriptionSettings={props.subscriptionSettings ?? {}} key={index} self_subscription_enabled={props.orgInfo?.self_subscription_enabled} isTableView={isTableView}  dispatch={props.dispatch} group={group} users={props.users}  user={props.user} manualFixReport={manualFixReport} drawerClass='library-drawer-grid' editMode={editMode} disableHome dropFuncs={dropFuncs} deleteGroup={promptDeleteGroup} editGroupName={onEditGroupName} toggleSelect={toggleSelect} selectedReports={selectedReports} openGroups={openPrivateGroups} setOpenGroups={setOpenPrivateGroups} setBucketFilter={setBucketFilter} setOwnerFilter={setOwnerFilter} setSelect={setSelect}/>
                                        )
                                        })}
                                    
                                        
                                    </div>
                                    </ShowIf>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            {reportToFix != null ?
                <FixReportDialog data={reportToFix} show={showFix} onClose={() => setShowFix(false)} dispatch={props.dispatch} />
                :
                null
            }
            <SaveDialog title="Move to group" ref={changeGroupDialogRef} saveHandler={() => saveMoveGroup()} closeHandler={() => {setReportsToEdit([]); setMoveToGroup(null)}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <span>New group:</span>
                    <select className="form-control form-control-sm margin-left-5px" style={{width: 300}} value={JSON.stringify(moveToGroup)} onChange={(e) => {setMoveToGroup(JSON.parse(e.target.value))}}>
                        {
                            [...otherGroupsForDialogs].sort((a, b) => a.group.name.localeCompare(b.group.name)).map((item, index) => {
                                return <option key={index} value={JSON.stringify(item.group)}>{item.group.name}</option>
                            })
                        }
                        <option value={JSON.stringify(unsortedGroup.group)}>{unsortedGroup.group.name}</option>
                    </select>
                </div>
            </SaveDialog>
            
            <SaveDialog ref={changeOwnerDialogRef} title="Change owner" saveHandler={() => saveNewOwner()} closeHandler={() => {setReportsToEdit([]); setNewOwner(null)}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <span>New owner:</span>
                    <select className="form-control form-control-sm margin-left-5px" style={{width: 300}} value={JSON.stringify(newOwner)} onChange={(e) => {setNewOwner(JSON.parse(e.target.value))}}>
                        {
                            selectionUsers.sort((a, b) => `${a.firstname} ${a.lastname}`.localeCompare(`${b.firstname} ${b.lastname}`)).map((user, index) => {
                                return <option key={index} value={JSON.stringify(user)}>{user.firstname} {user.lastname}</option>
                            })
                        }
                    </select>
                </div>
            </SaveDialog>

            <SaveDialog ref={changeGroupNameRef} title="Change group name" saveHandler={saveGroupName} closeHandler={() => {setTmpGroupName(''); setGroupToEdit(null)}} disableSave={tmpGroupName == groupToEdit?.group.name || tmpGroupName == ''}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <span>Name:</span>   
                    <input className="form-control form-control-sm" placeholder="Name" value={tmpGroupName} onChange={(e) => { setTmpGroupName(e.target.value) } } />
                </div>
            </SaveDialog>

            <WarningAcceptDialog ref={confirmGroupDeleteDialogRef} closeHandler={() => setGroupToEdit(null)} accept={deleteLibraryGroup}>
                <div>
                    <span>Are you sure you want to delete this group? This can not be undone.</span>
                </div>
            </WarningAcceptDialog>

            <SaveWithWarningDialog ref={confirmReportsDeleteDialogRef} closeHandler={() => setReportsToEdit([])} saveHandler={saveDeleteReports} shouldConfirm={() => reportsToEdit.some(report => report.inDashboard)} warningLabel="One or more of these reports are used in dashboards. Deleting these reports will remove them from those dashboards." saveText="Accept">
                <div>
                    <span>Are you sure you want to delete {reportsToEdit.length == 1 ? "this Insight?" : "these Insights?"}</span>
                    <ul>
                        {reportsToEdit.map((report, i) => <li key={i}>{report.report.name} {report.inDashboard ? <b>Used in a dashboard</b> : null}</li>)}
                    </ul>
                    <ShowIf if={reportsToEdit.some(report => report.inDashboard)}>
                        <div className='alert alert-danger'>
                            <span>One or more of these Insights are used in dashboards.</span>
                        </div>
                    </ShowIf>
                </div>
            </SaveWithWarningDialog>

            <SaveDialog ref={changePublicDialogRef} title="Change Insight status" closeHandler={() => {setReportsToEdit([]); setMoveToGroup(null)}} saveHandler={saveReportStatus}>
                {reportsToEdit.some(r => r.report.is_public) ? 
                    <span>You are about to make {reportsToEdit.length == 1 ? "this Insight" : "these Insights"} private. Doing this will remove access for other users in your organization.</span>
                :
                    <div>
                        <span>Making {reportsToEdit.length == 1 ? "this Insight" : "these Insights"} public will make {reportsToEdit.length == 1 ? "it" : "them"} visible to all users in your organization.</span>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <span>Group: </span>
                            <select className="form-control form-control-sm margin-left-5px" style={{width: 300}} value={JSON.stringify(moveToGroup)} onChange={(e) => {setMoveToGroup(JSON.parse(e.target.value))}}>
                                {
                                    [...otherGroupsForDialogs].sort((a, b) => a.group.name.localeCompare(b.group.name)).map((item, index) => {
                                        return <option key={index} value={JSON.stringify(item.group)}>{item.group.name}</option>
                                    })
                                }
                                <option value={JSON.stringify(unsortedGroup.group)}>{unsortedGroup.group.name}</option>
                            </select>
                        </div>
                    </div>
                }
            </SaveDialog>

            <SaveDialog ref={copyReportDialogRef} title="Save Insight as..." disableSave={tmpReportName == ''} closeHandler={() => {setReportsToEdit([]); setTmpReportName(''); setTmpFav(false); setTmpReportDescription('')}} saveHandler={saveCopyReport}>
                <div>
                    <span>Name: </span>
                    <input className="form-control form-control-sm" placeholder="Name" value={tmpReportName} onChange={(e) => { setTmpReportName(e.target.value) } } />
                    <br />
                    <span>Description: </span>
                    <textarea placeholder="Add description" className="width-100-p height-220px textarea-padding no-resize margin-top-10px textarea-border" value={tmpReportDescription} onChange={(e) => setTmpReportDescription( e.target.value)} />
                    <br /><br />
                    <input type="checkbox" className="vertical-align-middle" onChange={(e) => setTmpFav(e.target.checked)} />
                    &nbsp; <label> Add to Home page </label>
                </div>
            </SaveDialog>

            
            <RequirePermission perms={is.reportCreator}>
                <CreateReportDialog 
                    show={showCreateReport}
                    onClose={() => setShowCreateReport(false)}
                    history={props.history}
                    users={props.users}
                    //@ts-ignore
                    user={props.user} 
                />
            </RequirePermission>
            {
                selectedReports.length > 0 ? <div style={{height: 75}} /> : null
            }
        </BgColor>
    )
}

// @ts-ignore
Library = connect(mapStateToProps)(Library)

export default Library

export interface LibraryDropdownFunctions {
    onEdit: (report: LibraryReport) => void,
    onMoveTo: (report: LibraryReport, currentGroup: Group) => void,
    onChangeOwner: (report: LibraryReport) => void,
    onTogglePrivate: (report: LibraryReport) => void,
    onCopy: (report: LibraryReport) => void,
    onDelete: (report: LibraryReport) => void,
}

interface LibraryGroupProps {
    group: LibraryGroup, 
    users: User[], 
    icon?: React.ReactNode, 
    defaultShow?: boolean, 
    manualFixReport: (report: LibraryReport) => void, 
    drawerStyle?: React.CSSProperties, 
    drawerClass?: string, 
    draggable?: boolean, 
    editMode: boolean, 
    disableHome?: boolean, 
    dropFuncs: LibraryDropdownFunctions, 
    deleteGroup: (group: LibraryGroup) => void,
    editGroupName: (group: LibraryGroup) => void, 
    toggleSelect: (report: LibraryReport) => void, 
    setSelect: (value: boolean, ...reports: LibraryReport[]) => void,
    selectedReports: LibraryReport[],
    openGroups?: Set<number>,
    setOpenGroups?: (s: Set<number>) => void,
    isTableView: boolean,
    user: LoginSession,
    dispatch: any,
    setOwnerFilter: (userid: number) => void,
    setBucketFilter: (bucket: string) => void,
    mailbundles?: MailBundleWithRelations[]
    plans?: Plan[]
    self_subscription_enabled: boolean
    subscriptionSettings: OrganizationSubscriptionSettings
}

const LibraryGroupElement = (props: LibraryGroupProps) => {

    const [show, setShow] = useState(props.defaultShow ?? false)
    const [sort, setSort] = useState("name")
    const [sortDirection, setSortDirection] = useState(false)

    let isAdmin = isReportAdmin(props.user.access)

    const [showMailSignupModal, setShowMailSignupModal] = useState<boolean>(false)
    const [selectedReport, setSelectedReport] = useState<Report>()

    const mailBundlesWithUser = useMemo(() => {
        if(!props.mailbundles) return [];
        return props.mailbundles.filter(m => m.user_ids.includes(props.user.user_id))
    }, [props.mailbundles, props.user])
    
    const userSelfSubscriptions = useMemo(() => 
        mailBundlesWithUser.filter(mb => mb.self_subscription && mb.owner_id === props.user.user_id)
    ,[mailBundlesWithUser]) 
    
    const userSubscribesToInsight = useMemo(() => 
        userSelfSubscriptions.length > 0 ? userSelfSubscriptions.flatMap(mb => mb.report_ids).some(id => id === selectedReport?.report_id) : false
    , [userSelfSubscriptions, selectedReport])
    
    const mailBundleWithInsight = useMemo(() =>
        mailBundlesWithUser
            .filter(mb => !mb.self_subscription)
            .find(mb => mb.report_ids.some(id => id === selectedReport?.report_id))
    ,[mailBundlesWithUser, selectedReport])
    
    let getIcon = (report : LibraryReport) => {
        if (report.report.is_public) {
            return <img className='library-item-icon' src='img/report.svg' alt=""/>
        }
        return <img className='library-item-icon' src='img/report-private.svg' alt=""/>
    }
    let toggleAll = (value: boolean) => {
        props.setSelect(value, ...(props.group.reports ?? []))
    }
    let toggleFav = (report: LibraryReport) => {
        let { dispatch } = props

        let data = {
            favourited: !report.isFav,
            item_type: 'report',
            item_id: report.report.report_id
        }
        dispatch(toggleFavourite(data))
    }

    let getReportDescription = (report: LibraryReport) => {
        let reportFilters: QueryCriterion[] = JSON.parse(report.report.filter)
        let columns: string[] = JSON.parse(report.report.columns)
        return (
            <div>
                <ReportDescription description={report.report.description} filterDescription={getDataDescriptionDialogDescription("Insight")(report.report.limit, report.report.sort_direction, report.report.sort_column, reportFilters.length)} filter={reportFilters} showColumnDescription={false} title={"About this Insight"} columns={columns} columnDescription={report.info.setup.descriptions} />
                {report.inDashboard ? <b>** This Insight is used in a Dashboard **</b>: null}
            </div>
        )
    }
    
    const userIsSubscribedViaBundle = (report_id: number) => {
       return mailBundlesWithUser.filter(mb => !mb.self_subscription).find(mb => mb.report_ids.some(id => id === report_id))
    }

    let fixReport = (report: LibraryReport) => {
        const critical = report?.critical != null
        if (!critical) {
            const { dispatch } = props
            const loc = "library"
            dispatch(removeColumns(report?.report.report_id, { view: loc }))
        } else {
            props.manualFixReport(report)
        }
    }

    const ReportJSX:FC<{report : LibraryReport, id : React.Key}> = ({report, id}) => {
       
        let isChecked = props.selectedReports.some(v => v.report.report_id == report.report.report_id)
        let disableHome = props.disableHome ?? false

        let olderThan24Hours = (((new Date()).getTime() / 1000) - report.info.last_update) > 86_400

        let error =  report.missing != null || report.critical != null
        let critical = report.critical != null
        
        let baseUrl = window.location.href.substring(0, window.location.href.indexOf("#"))

        const userIsSubscribed = useMemo(() => 
            !!userSelfSubscriptions ? userSelfSubscriptions.flatMap(mb => mb.report_ids).some(id => id === report.report.report_id) : false
        , [userSelfSubscriptions, report])
        
        const mailBundleWithReport = useMemo(() =>
            mailBundlesWithUser
                .filter(mb => !mb.self_subscription)
                .find(mb => mb.report_ids.some(id => id === selectedReport?.report_id))
        ,[mailBundlesWithUser, report])

        const userGetsInsightByBundle = useMemo(() => 
            !!mailBundleWithReport
        , [mailBundlesWithUser, mailBundleWithReport])

        return (
            <tr key={id} className={`all-white-space-nowrap ${report.isFav?'library_favourite':''}`}>
                <td className="width-40 text-align-center">
                    <ShowIf if={isAdmin}>
                        <input checked={isChecked} type="checkbox" onChange={() => props.toggleSelect(report)}/>
                    </ShowIf>
                    {getIcon(report)}
                </td>
                <td>
                    <a className='abc-click' style={{textDecoration:"none"}} href={baseUrl + `#/report/${report.report.report_id}`}><div style={{width:"100%"}}>
                        <span className='library-item-title-top' title={report.report.name}>{report.report.name}</span> <i data-tip data-for={`tooltip-${report.report.report_id}`} style={{display:"inline-block"}} className='fa fa-info-circle glyphicon-info-gray'></i>
                        <ReactTooltip id={`tooltip-${report.report.report_id}`} effect='solid' place='bottom' className='react-tooltip-extra'>
                            <p style={{textDecoration: `underline`}} className={`margin-top-0 ${olderThan24Hours ? 'library-item-red-bold' : ''}`}>Data updated: <span title="">{fromNow(report.info.last_update)}</span></p>
                            <div style={{maxWidth:800, whiteSpace:"normal"}}>
                            {getReportDescription(report)}
                            </div>
                        </ReactTooltip>
                        </div>
                    </a>
                </td>
                <td style={{width:200}}>
                    <span className="filter-option" onClick={() => props.setOwnerFilter( report.report.user_id)} >
                       {getFullName(props.users, report.report.user_id)}
                   </span>
                </td>
                <td style={{width:200}}>
                    <span className="filter-option" onClick={() => props.setBucketFilter(report.info.name)} >
                        {report.info.name}
                    </span>
                </td>

                <td className="width-100px">
                     {error ? <div>
                        <i onClick={()=>fixReport(report)} style={{color: "#D53939", marginLeft: 10, fontSize: 20}} className="fa fa-exclamation-triangle abc-click" aria-hidden="true" data-tip data-for={`tooltip-${report.report.report_id}-error`}></i>
                        <ReactTooltip id={`tooltip-${report.report.report_id}-error`} effect='solid' place='bottom'>
                            {critical ? <span>This Insight contains columns that are no longer available. <br/> Click to fix the Insight.</span> : <span>This Insight contains columns that are no longer available. <br/> Click to remove these columns from the report</span>}
                        </ReactTooltip>
                    </div> : null}
                    <div className='library-item-content-right'>
                        <ShowIf if={!disableHome}>
                            <ResponsiveToggle style={{marginRight: 5}} onChange={() => {toggleFav(report)}} checked={report.isFav} label='Add'>
                                <HomeIcon width={10} height={10} add={!report.isFav}/>
                            </ResponsiveToggle>
                        </ShowIf>
                        <ShowIf if={(!!userIsSubscribedViaBundle(report.report.report_id) || userIsSubscribed) && props.self_subscription_enabled && props.subscriptionSettings.enable_insight} >
                            <div 
                                className={`btn-primary abc-click responsive-button-container`} 
                                title={userIsSubscribed ? "You recieve this Insight by mail" : "Sign up to recieve Dashboard by mail"}
                                onClick={() => {
                                    setSelectedReport(report.report);
                                    setShowMailSignupModal(true);
                                }}
                                style={{width:22}}
                            >   
                                <span className={`responsive-button-label d-flex text-light align-items-center`}>
                                    <FaEnvelope width={8} height={8}/>
                                </span>
                            </div>
                        </ShowIf>
                        <ShowIf if={isAdmin}>
                            <div className="btn-group width-100-p margin-left-5px" role="group" aria-label="...">
                                <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-xs btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li className='abc-click'><span onClick={() => props.dropFuncs.onEdit(report)}>Edit Insight</span></li>
                                        <ShowIf if={!!props.mailbundles && !!props.plans && props.self_subscription_enabled && props.subscriptionSettings.enable_insight}> 
                                            <li className={`${userGetsInsightByBundle || userSubscribesToInsight ? "bg-primary text-light" : ""} abc-click`}>
                                                <span 
                                                    onClick={() => {
                                                        setSelectedReport(report.report);
                                                        setShowMailSignupModal(true);
                                                    }}
                                                    className={userGetsInsightByBundle || userIsSubscribed ? "bg-primary text-light" : ""}
                                                >
                                                    {userGetsInsightByBundle || userIsSubscribed ? "Subscribed" : "Subscribe"}
                                                    <FaEnvelope className='ml-1'/>
                                                </span>
                                            </li>
                                        </ShowIf>
                                        <ShowIf if={report.report.is_public}>
                                            <li className='abc-click'><span onClick={() => props.dropFuncs.onMoveTo(report, props.group?.group)}>Move to group</span></li>
                                        </ShowIf>
                                        <li className='abc-click'><span onClick={() => props.dropFuncs.onChangeOwner(report)}>Change owner</span></li>
                                        <li className='abc-click'><span onClick={() => props.dropFuncs.onTogglePrivate(report)}>
                                            {report.report.is_public ? "Make private" : "Make public"}</span>
                                        </li>
                                        <li className='abc-click'><span onClick={() => props.dropFuncs.onCopy(report)}>Copy Insight</span></li>
                                        <li className='abc-click'><span onClick={() => props.dropFuncs.onDelete(report)}>Delete</span></li>
                                    </ul>
                                </div>
                            </div>
                        </ShowIf>
                        </div>
                </td>
            </tr>
        );
    }


    let reportsComparer = (a: LibraryReport,b :LibraryReport) => {
        let sortType = sort
        let x = sortDirection? 1 : -1
        let y = sortDirection? -1 : 1
        switch(sortType) {
            case 'datasource': 
                return (a.info.name<b.info.name?x:(a.info.name>b.info.name?y:0))
            case 'owner':
                let aowner = getFullName(props.users, a.report.user_id)
                let bowner = getFullName(props.users, b.report.user_id)
                return (aowner<bowner?x:(aowner>bowner?y:0))
            case 'name':
            default:
                return (a.report.name<b.report.name?x:(a.report.name>b.report.name?y:0))
        }
    }

    let handleSort = (sortType:string) => {
        if(sort === sortType) {
            setSortDirection(!sortDirection)
        } else {
            setSort(sortType)
            setSortDirection(false)
        }
    }


    useEffect(() => {
        if (props.openGroups != null){
            let contains = props.openGroups.has(props.group.group.group_id)
            setShow(contains)
        }
    }, [props.openGroups, props.group])

    let toggleShow = () => {
        if (props.openGroups != null && props.setOpenGroups != null) {
            if(show){
                let tmp = new Set(props.openGroups)
                tmp.delete(props.group.group.group_id)
                props.setOpenGroups(tmp)
            } else {
                let tmp = new Set(props.openGroups)
                tmp.add(props.group.group.group_id)
                props.setOpenGroups(tmp)
            }
        } else {
            setShow(!show)
        }
    }

    let getShownState = () => {
        if (show) {
            return "fa-chevron-down"
        } else {
            return "fa-chevron-right"
        }
    }

    const subscribe = (plan: Plan) => {
        if(!selectedReport) return;
        
        // Add insight to existing subscribtion with same plan
        if(userSelfSubscriptions.length !== 0 && !userSelfSubscriptions.flatMap(mb => mb.report_ids).some(id => id === selectedReport.report_id) && userSelfSubscriptions.some(mb => mb.plan_id === plan.id)){
            const mb = userSelfSubscriptions.find(mb => mb.plan_id === plan.id)
            if(mb){
                mb.report_ids.push(selectedReport.report_id)
                props.dispatch(updateMailBundle(mb))
                return;
            }
        }

        // create subscribtion if doesnt exist
        const mb: MailBundleWithRelations = newMailBundle(selectedReport.organization_id)
        mb.active = true
        mb.report_ids.push(selectedReport.report_id)
        mb.name = props.user.firstname + " " + props.user.lastname + " - Subscription"
        mb.link = true
        mb.user_ids.push(props.user.user_id)
        mb.plan = plan.interval
        mb.plan_id = plan.id
        mb.self_subscription = true
        mb.owner_id = props.user.user_id    
        props.dispatch(createMailBundle(mb))
    }

    const unsubscribe = () => {
        if(userSelfSubscriptions.length === 0 || !selectedReport) return;
        const userSelfSubscription = userSelfSubscriptions.find(mb => mb.report_ids.includes(selectedReport.report_id))

        if(!userSelfSubscription) return;
        const hasTrendViews = userSelfSubscription.insight_views.length > 0
        const hasReports = userSelfSubscription.report_ids.length > 1
        const hasMultipleDashboards = userSelfSubscription.dashboards.length > 0
        const hasOtherItems = hasTrendViews || hasReports || hasMultipleDashboards
        
        if(hasOtherItems){
            const mb = userSelfSubscription
            mb.report_ids = mb.report_ids.filter(id => id !== selectedReport.report_id)
            props.dispatch(updateMailBundle(mb))
            return;
        }
        props.dispatch(deleteMailBundle(userSelfSubscription)) // Delete mailbundle if no other items
    }

    let disableHome = props.disableHome ?? false
    let draggable = props.draggable ?? false
    let drawerClass = props.drawerClass ?? ""

    let totalReports = 0
    if (props.group.reports != null) {
        totalReports = props.group.reports.length
    }
    const isAllChecked = props.group.reports?.every(r => props.selectedReports.some(r2 => r.report.report_id === r2.report.report_id)) ?? false
    return (
    <div className='library-group'>
        <div className='abc-click' style={{display: "flex", alignItems: "center", minHeight: 30, paddingLeft: 10}} onClick={toggleShow}>
            {
                props.icon == null ?
                    null
                :
                    props.icon
            }
            <span className='library-group-label'>{props.group?.group.name}   <span style={{color: "hsl(0,0%,50%)"}}><span style={{marginRight: 5, marginLeft: 5}}>|</span> {totalReports}</span> <i className={`margin-left-5px fa ${getShownState()}`}></i></span>
            <ShowIf if={draggable && props.editMode} >
                <DragHandle className="sortable-list-handle-black" />
                <i style={{marginRight: 5}} className='fa fa-pencil abc-click' onClick={(e) => { e.stopPropagation(); props.editGroupName(props.group)}}></i>
                <i className="fa fa-trash abc-click" onClick={() => props.deleteGroup(props.group)}></i>

            </ShowIf>
            
        </div>
        <Drawer expand={show}>
            {props.group?.reports == null ?
                    <span style={{marginLeft: 10}}>No Insights to show</span>
                    :
                    <>
                        <ShowIf if={props.isTableView}>
                            <StandardTable className="library-table">
                                <thead>
                                    <tr className="all-white-space-nowrap">
                                        <th >
                                        <ShowIf if={isAdmin}>
                                            <input checked={isAllChecked} type="checkbox" onChange={() => toggleAll(!isAllChecked)}/>
                                        </ShowIf>
                                        </th>
                                        <th className='abc-click' onClick={() => handleSort("name")}>
                                            Name <SortArrow sortDirection={!sortDirection} mySymbol="name" currentSymbol={sort} />
                                        </th>
                                        <th className='abc-click' onClick={() => handleSort("owner")}>
                                            Owner <SortArrow sortDirection={!sortDirection} mySymbol="owner" currentSymbol={sort} />
                                        </th>
                                        <th className='abc-click' onClick={() => handleSort("datasource")}>
                                            Data Source <SortArrow sortDirection={!sortDirection} mySymbol="datasource" currentSymbol={sort} />
                                        </th>
                                        <th>
                                            &nbsp; {/* add to home + menu column */}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {props.group.reports
                                    .sort((a, b) => reportsComparer(a, b) )
                                    .map((report, i) => {
                                        return <ReportJSX key={i} id={i} report={report}/>
                                })}
                                </tbody>
                            </StandardTable>
                        </ShowIf>
                        <ShowIf if={!props.isTableView}>
                            <div className={drawerClass} style={props.drawerStyle ?? {}}>
                            {props.group.reports.map((report, index) => {
                                return (
                                    <LibraryItem key={index} report={report} users={props.users} manualFixReport={props.manualFixReport} dispatch={undefined} disableHome={disableHome} dropFuncs={props.dropFuncs} group={props.group?.group} toggleSelect={props.toggleSelect} selectedReports={props.selectedReports}/>
                                )
                            })} 
                            </div>
                        </ShowIf>
                   </>
                }
        </Drawer>
        {showMailSignupModal && !!props.plans && !!props.mailbundles && selectedReport &&
            <InsightMailSignupModal
                mailbundle={mailBundleWithInsight}
                onClose={() => {setShowMailSignupModal(false); setSelectedReport(undefined);}}
                plans={props.plans}
                show={showMailSignupModal}
                report={selectedReport}
                subscription={userSelfSubscriptions.find(mb => mb.report_ids.includes(selectedReport.report_id))}
                subscribed={userSubscribesToInsight}
                onSubscribe={subscribe}
                onUnsubscribe={unsubscribe}
            />
        }
    </div>

    )
}
