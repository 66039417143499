import type { Checklist, ChecklistColumn, Column, ChecklistStatus } from '../types/transfertypes'
import { DecoratedChecklist, DecoratedChecklistStatus, IndexedColumn } from '../types/types'

export const getChecklistStatus = (checklist:ChecklistStatus) => {
    let total_count = 0
    let undone_count = 0
    let layout = JSON.parse(checklist.list.layout)

    for (var key in checklist.count) {
        total_count += checklist.count[key]
        if(layout[key] === undefined){
            undone_count += checklist.count[key]
            console.log("yikes - layout is broken")
        }
        else if(layout[key].donecount === undefined){
            if (key === '0') { 
                undone_count += checklist.count[key]
            }
        } else {
            if (!layout[key].donecount) {
                undone_count += checklist.count[key]
            }
        }
    }

    let result = {total: total_count, undone: undone_count, done: total_count-undone_count}
    return result
}

export const decorateChecklists = (checklists:ChecklistStatus[]) => {
    let decoratedChecklists:DecoratedChecklistStatus[] = []
    checklists.forEach(checklist => {
        decoratedChecklists.push(decorateChecklist(checklist))
    })
    return decoratedChecklists
}

export const decorateChecklist = (checklist:ChecklistStatus) => {
    const decoratedList:DecoratedChecklist = checklist.list
    const decoratedChecklist: DecoratedChecklistStatus = {
        id: checklist.id,
        count: checklist.count,
        list: decoratedList
    }
    let itemCount = Object.keys(decoratedChecklist.count).reduce((acc,key) => acc + decoratedChecklist.count[key], 0)
    decoratedChecklist.list.overdue = !decoratedChecklist.list.completed && decoratedChecklist.list.has_deadline && Date.parse(decoratedChecklist.list.deadline) < Date.now()
    decoratedChecklist.list.not_started = (itemCount === 0 || decoratedChecklist.count['0'] === itemCount) && !decoratedChecklist.list.completed
    decoratedChecklist.list.started = !decoratedChecklist.list.not_started
    return decoratedChecklist
}

export const countChecklistStatus = (checklists:DecoratedChecklistStatus[]) => {
    let result = {active: 0, not_started: 0, overdue: 0, archived: 0, completed: 0}
    if(checklists.length < 1) return result
    checklists.forEach(checklist => {
        //if(checklist.list == undefined) return
        if(checklist.list.archived) result.archived++
        if(!checklist.list.archived && checklist.list.completed) result.completed++
        if(!checklist.list.archived && !checklist.list.completed && checklist.list.not_started) result.not_started++
        if(!checklist.list.archived && checklist.list.overdue) result.overdue++
        if(!checklist.list.archived) result.active++
    })
    return result
}

export const checklistColumnToDataGridColumn = (columns:ChecklistColumn[], checklist:Checklist):IndexedColumn[] => {
    let data_columns:ChecklistColumn[] = JSON.parse(checklist.data_columns)

    return columns.filter(x => x != undefined).map((c,i) => {
            return {name: c.Name, type: intColTypeToStringColType(c.ColumnType), index: data_columns.findIndex(dc => dc.Name === c.Name)}
    })
}

export const checklistModelColumnToDataGridColumn = (columns:Column[]):IndexedColumn[] => {
    return columns.filter(x => x != undefined).map((c,i) => {
            return {name: c.name, type: c.type, index: i}
    })
}

export const intColTypeToStringColType = (columnType:number) => {
    switch(columnType) {
        case 1:
            return 'text'
        case 2:
            return 'id'
        case 4:
            return 'decimal'
        case 8:
            return 'date'
        case 16:
            return 'categorization'
        case 32:
            return 'ignore'
        case 64:
            return 'categorization'
        case 128:
            return 'calculated'
        default:
            return 'unknown'
    }
}
