import React, { useEffect, useState } from 'react'
import GenericDialog from '../Dialogs/GenericDialog'
import { ActionTemplate, ActionTemplateGroupWrapper, ActionTemplateBucket } from '../../types/types'
import { connect } from 'react-redux'
import { getActionTemplates, createActionFromTemplate } from '../../actions/TemplateActions'
import ShowIf from '../Generic/ShowIf'

import '../../css/CreateReportDialog.css'
import InfoTooltip from '../Generic/InfoTooltip'
import { notifyFailure } from '../../helpers/NotificationManager'
// @ts-ignore
import Select from 'react-select'
import { SelectOption } from '../../helpers/TypeHelpers'
import { User, LoginSession } from '../../types/transfertypes'
import RequirePermission from '../RequirePermission'
import { is } from '../../helpers/PermissionHelpers'
import SmallTabs, { SmallTabOption } from '../SmallTabs'
import { BsCardChecklist } from "react-icons/bs";

interface CreateActionDialogProps {
    show: boolean,
    onClose: () => void,
    templates?: ActionTemplateGroupWrapper,
    users: User[],
    user: LoginSession,
    dispatch?: (msg: any) => void,
    history: any,
}

const mapStateToProps = (state: any, ownProps: CreateActionDialogProps) => {
    return {
        templates: state.Checklist.actionTemplateGroups,
        users: state.System.users,
        user: state.User.info,
    }
}

let CreateActionDialog = ({show, users = [], templates = {fetched: false, buckets: [], groups: [], enabled_groups: [], enabled: false}, dispatch = (() => {}), ...props }: CreateActionDialogProps) => {

    const [stage, setStage] = useState<"initial" | "template" | "creating">("initial")
    const [template, setTemplate] = useState<ActionTemplate>()
    const [possibleBuckets, setPossibleBuckets] = useState<ActionTemplateBucket[]>([])
    const [tmpName, setTmpName] = useState("")
    const [tmpBucketId, setTmpBucketId] = useState<SelectOption<string>>({label: "", value: ""})
    const [tmpPrivate, setTmpPrivate] = useState(false)
    const [tmpOwnerId, setTmpOwnerId] = useState<SelectOption<number>>()
    const [tmpAssigneeId, setTmpAssigneeId] = useState<SelectOption<number>>()

    const [tab, setTab] = useState(0)
    
    useEffect(() => {
        if(!templates.fetched) {
            dispatch(getActionTemplates())
        }
    }, [])

    useEffect(() => {
        if (template == undefined) {
            setTmpBucketId({label: "", value: ""})
            setPossibleBuckets([])
            return
        }
        let buckets = templates.buckets.filter(b => b.bucket_template_id == template.bucket_id)
        setPossibleBuckets(buckets)
        if (buckets.length < 1) {
            setTmpBucketId({label: "", value: ""})
            notifyFailure(`An error has occured getting datasources for the ${template.name} template that should not have happened. Please notify support of this error.`, template)
            return
        }
        setTmpBucketId({value: buckets[0].id, label: buckets[0].name})
    }, [template])

    useEffect(() => {
        setStage("initial")
    }, [show])

    if (!templates.enabled) return <></>

    const pickTemplate = (t: ActionTemplate) => {
        setTemplate(t)
        setTmpName(t.name)
        setTmpPrivate(false)
        setTmpOwnerId({label: `${props.user.firstname} ${props.user.lastname}`, value: props.user.user_id})
        setStage("template")
    }

    const disableSave = () => {
        return stage != "template" || tmpName == "" || template == undefined || tmpBucketId.value == "" || !tmpAssigneeId || !tmpOwnerId 
    }

    const createAction = () => {
        if (disableSave() || template == undefined || !tmpOwnerId || !tmpAssigneeId) return
        setStage("creating")
        dispatch(createActionFromTemplate({
            template_id: template.id,
            name: tmpName,
            private: tmpPrivate,
            owner_id: tmpOwnerId.value,
            bucket_id: tmpBucketId.value,
            assignee_id:tmpAssigneeId.value,
            org_id: props.user.organization
        }, 
            // On success
            (actionId) => {
                props.history.push("/checklist/" + actionId)
            }, 
            // On error
            () => {
                setStage("template")
            }))
    }

    const getButtons = () => {
        return <div>
            <ShowIf if={stage == "template"}>
                <button style={{marginRight: 7}} onClick={createAction} disabled={disableSave()} className='btn btn-success'>Create</button>
            </ShowIf>
            <button onClick={() => props.onClose()} className='btn btn-default'>Cancel</button>
        </div>
    }

    const getPopupTitle = () => {
        if (stage == "template" && template != undefined) {
            return `Template - ${template.name}`
        }

        return "Choose Action template"
    }

    const filteredGroups = templates.groups.filter(tg => tg.action_templates.length > 0)
    const tabs: SmallTabOption<number>[] = filteredGroups.map((tg, i) => {
        const isEnabled = templates.enabled_groups.some(g => g == tg.id)
        return {
            label: <span>
            <ShowIf if={!isEnabled}><i style={{marginRight: 5}} className="fa fa-lock" aria-hidden="true"></i></ShowIf>
            {tg.name}
            </span>, value: i}
    })
    const g = filteredGroups.at(tab)
    const isEnabled = g == undefined ? false : templates.enabled_groups.some(g2 => g2 == g.id)
    
    return <> 
        <GenericDialog show={show} title={getPopupTitle()} onClose={props.onClose} getButtons={getButtons} style={{minWidth: 400}}>
            <ShowIf if={stage == "initial"}>
                <SmallTabs
                    options={tabs}
                    value={tab}
                    onChange={setTab}
                />

                {
                    
                g != undefined ? <div className={`templates-content ${!isEnabled ? 'disabled' : ''}`}>
                        <ShowIf if={g != undefined && !isEnabled}>
                            <div className="alert alert-warning">
                                You do not have access to these templates, contact <a href="mailto:support@inact.io"><b>support@inact.io</b></a> to gain access.
                            </div>
                        </ShowIf>
                    <h3 className={`report-template-group-title ${!isEnabled ? 'disabled' : ''}`}>{g.name}</h3>
                    <div className={`templates-grid ${!isEnabled ? 'disabled' : ''}`}>
                        {
                            g.action_templates.map((a) => {
                                return <div key={a.id} className={`report-template-card ${!isEnabled ? 'disabled' : ''}`} onClick={() => {if(isEnabled) pickTemplate(a)}}>
                                    <BsCardChecklist className={`${!isEnabled ? 'disabled' : ''} h3 my-auto`}/>
                                    <div className="d-flex flex-column">
                                        <span className={`report-template-name ${!isEnabled ? 'disabled': ''}`}>
                                                {a.name}
                                                <ShowIf if={a.description != ""}>
                                                    <InfoTooltip text={a.description} style={{marginLeft: 5, zIndex: 999, pointerEvents: "initial"}} />
                                                </ShowIf>

                                            </span>
                                            <div style={{opacity: 0.5, fontSize: 12}}>
                                                <span style={{fontWeight: "700"}}>Datasource</span> {templates.buckets.find(b => b.bucket_template_id == a.bucket_id)?.name ?? "None"}
                                            </div>

                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                : <h3>Loading...</h3>
                }
            </ShowIf>
            <ShowIf if={stage == "template"}>
                <div>
                    <span className="abc-click report-template-back" onClick={() => setStage("initial")}><i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back</span>
                </div>
                <div>
                    <label htmlFor='kpi-label' className='select-label mt-3'>Name</label>
                    <input id='kpi-label' type="text" placeholder="Input name of report" className="form-control" value={tmpName} onChange={(e) => setTmpName(e.target.value)} />
                    <ShowIf if={possibleBuckets.length > 1}>
                        <label className='select-label mt-3' >Select datasource</label>
                        <Select
                            isSearchable
                            options={possibleBuckets.map(b => ({label: b.name, value: b.id}))}
                            value={tmpBucketId}
                            onChange={(v: any) => setTmpBucketId(v)}
                            placeholder={"Select data source"}
                        />
                    </ShowIf>
                    <RequirePermission perms={is.checklistsAdmin}>
                        <label className='select-label ' >Owner</label>
                        <Select
                            isSearchable
                            options={users.map(u => ({label: `${u.firstname} ${u.lastname}`, value: u.id})).sort((a, b) => a.label.localeCompare(b.label))}
                            value={tmpOwnerId}
                            onChange={(v: SelectOption<number>) => setTmpOwnerId(v)}
                            placeholder={"Owner"}
                        />
                        <label className='select-label ' >Assignee</label>
                        <Select
                            isSearchable
                            options={users.map(u => ({label: `${u.firstname} ${u.lastname}`, value: u.id})).sort((a, b) => a.label.localeCompare(b.label))}
                            value={tmpAssigneeId}
                            onChange={(v: SelectOption<number>) => setTmpAssigneeId(v)}
                            placeholder={"Assignee"}
                        />
                    </RequirePermission>

                </div>
            </ShowIf>
            <ShowIf if={stage == "creating"}>
                <h3>Creating report...</h3>
            </ShowIf>
        </GenericDialog>
    </>
}

// @ts-ignore
CreateActionDialog = connect(mapStateToProps)(CreateActionDialog)

export default CreateActionDialog
