import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getBucketInfo } from "../../actions/BucketActions";
import { editChecklist } from "../../actions/ChecklistActions";
import { getSlicerValues } from "../../actions/ReportActions";
import { SingleKeyMap } from "../../helpers/Collections";
import { getIdColumn, getNumberColumnsObj, isDateColumn, slicerEquals } from "../../helpers/ReportHelpers";
import { Checklist, QueryCriterion, BucketInfo, Column, ChecklistColumn } from "../../types/transfertypes";
import ActiveCategoryIcon from "../Report/ReportComponents/ActiveCategoryIcon";
import SingleSlicer2 from "../Report/SlicerComponents/SingleSlicer2";
import WarningAcceptDialog from "./WarningAcceptDialog";

import '../../css/EditChecklistDialog.css'
import { DECIMAL_TYPE, mapColumnTypes } from "../../helpers/TypeHelpers";
import AddSlicerDialog2 from "./AddSlicerDialog2";
import { getTodaysDate } from "../../helpers/GeneralHelpers";
import { CompareType } from "../../types/slicertypes";

export interface EditChecklistDialogProps {
    checklist: Checklist,
    dispatch?: any,
    buckets?: SingleKeyMap<BucketInfo>,
    slicerValues?: any,
    redirect: boolean,

    onClose: () => void,
}

let mapStateToProps = (state: any, ownProps: EditChecklistDialogProps) => {
    return {
        buckets: state.Bucket.buckets,
        slicerValues: state.Report.slicerValues,
        slicerAvailableValues: state.Report.slicerAvailableValues,
        reportInfo: state.Info.reportInfo,
    }
}

let EditChecklistDialog = (props: EditChecklistDialogProps) => {

    const [tmpChecklistHasLimit, setTmpChecklistHasLimit] = useState(false)
    const [tmpChecklistName, setTmpChecklistName] = useState("")
    const [tmpChecklistSortDirection, setTmpChecklistSortDirection] = useState("desc")
    const [tmpChecklistLimit, setTmpChecklistLimit] = useState(-1)
    const [tmpChecklistSortColumn, setTmpChecklistSortColumn] = useState("")

    const [addSlicerSearchStr, setAddSlicerSearchStr] = useState("")
    const [tmpSlicers, setTmpSlicers] = useState<QueryCriterion[]>([])
    const [tmpRemoveSlicer, setTmpRemoveSlicer] = useState<any>({})
    const [slicers, setSlicers] = useState<QueryCriterion[]>([])

    const warnRemoveSlicerRef = useRef<any>()

    const [slicerToEdit, setSlicerToEdit] = useState<QueryCriterion>()
    const [showEditSlicer, setShowEditSlicer] = useState(false)
    const [addingSlicer, setAddingSlicer] = useState(false)
    const [tmpChecklistHasKpi, setTmpChecklistHasKpi] = useState(false)
    const [tmpChecklistKpi, setTmpChecklistKpi] = useState("")
    
    useEffect(() => {
        props.dispatch(getBucketInfo(props.checklist.bucket))
        setTmpChecklistName(props.checklist.name)
        setTmpChecklistLimit(props.checklist.limit)
        setTmpChecklistSortDirection(props.checklist.sort_direction)
        setTmpChecklistSortColumn(props.checklist.sort_column)
        setTmpChecklistHasLimit(props.checklist.limit != -1)
        setSlicers(JSON.parse(props.checklist.filter).filter)
        setTmpSlicers(JSON.parse(props.checklist.filter).filter)
        setTmpChecklistHasKpi(props.checklist.selected_kpi != "")
        setTmpChecklistKpi(props.checklist.selected_kpi === "" ? defaultKpi : props.checklist.selected_kpi )
    }, [])

    if(props.buckets?.get(props.checklist.bucket) == undefined) {
        return <span>Loading...</span>
    }

    let getSlicerColumns = () => {
        return JSON.parse(props.checklist.data_columns).map((c: any) => {return {name: c.Name, type: mapColumnTypes(c.ColumnType)} }).sort((a: any, b: any) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        })
    }

    let openSlicer = (slicer: QueryCriterion) => {
        let checklist = props.checklist
        let bucket = props.buckets?.get(checklist.bucket)
        if (bucket == null) {
            return
        }
        props.dispatch(getSlicerValues(bucket.id, {column: slicer.column, filter: []}))
        setSlicerToEdit(slicer)
        setShowEditSlicer(true)
    }


    let confirmRemoveSlicer = (slicer: any) => {
        if (slicer.target_values.length > 1) {
            setTmpRemoveSlicer(slicer)
            warnRemoveSlicerRef.current.show()
        } else {
            removeSlicer(slicer)
        }
    }

    let removeSlicer = (slicer: any) => {
        let slicers = tmpSlicers.slice()
        let index = slicers.findIndex((s: any) => slicerEquals(s, slicer))
        if (index >= 0) {
            slicers.splice(index, 1)
            setTmpSlicers(slicers)
        }
    }

    let columnClick = (column: Column, adding = false) => {
        let bucket = props.buckets?.get(props.checklist.bucket)
        if (bucket == null) {
            return
        }

        let slicers = structuredClone(tmpSlicers)
        let target_values: any[] = []
        if (isDateColumn(column.name, bucket.model)) {
            target_values = [getTodaysDate()]
        }
        if(slicers.length > 0 && !adding) {
            let slicer = slicers.find(s => s.column === column.name)

            if (slicer){
                openSlicer(slicer)
            } else {
                setAddingSlicer(true)
                setSlicerToEdit({column: column.name, target_values: target_values, is_column: false, compare_type: CompareType.EQ })
            }
        } else {
            setAddingSlicer(true)
            setSlicerToEdit({column: column.name, target_values: target_values, is_column: false, compare_type: CompareType.EQ })
        }
        setShowEditSlicer(true)
    }
    
    let saveSlicer = (slicer: QueryCriterion) => {
        let slicers: QueryCriterion[] = structuredClone(tmpSlicers)

        if (addingSlicer) {
            if (slicer.target_values.length > 0) {
                slicers.push(slicer)
            }
        } else {
            if (slicerToEdit == undefined){
                if (slicer.target_values.length > 0) {
                    slicers.push(slicer)
                }
            } else {
                const index = slicers.findIndex(s => slicerEquals(s, slicerToEdit))
                if (index < 0) {
                    if (slicer.target_values.length > 0) {
                        slicers.push(slicer)
                    }
                } else {
                    if (slicer.target_values.length > 0) {
                        slicers[index] = slicer
                    } else {
                        slicers.splice(index, 1)
                    }
                }
            }
        }
        setTmpSlicers(slicers)
        setSlicerToEdit(undefined)
        setShowEditSlicer(false)
        setAddingSlicer(false)
    }

    let saveChecklist = (updateItems = false) => {
        let checklist = props.checklist
        let limit = tmpChecklistHasLimit ? tmpChecklistLimit : -1
        let sortColumn = tmpChecklistSortColumn
        let bucket = props.buckets?.get(checklist.bucket)
        let kpi = ""
        if (bucket === undefined) return
        if (!tmpChecklistSortColumn){
            let numberColumns: any[] = []
            if (props.buckets?.get(checklist.bucket) !== undefined){
                numberColumns = getNumberColumnsObj(bucket.model).map(c => c.name)
            }
            if (numberColumns.length < 1) return
            sortColumn = numberColumns[0]
        }
        if (tmpChecklistHasKpi) {
            kpi = tmpChecklistKpi
        } 

        if(checklist.selected_kpi != tmpChecklistKpi || checklist.limit != tmpChecklistLimit || checklist.sort_column != sortColumn || checklist.sort_direction != tmpChecklistSortDirection){
            //changes to setup, where items / data changes. We do a recalculation anyways!
            updateItems = true
        }
        let filter = JSON.parse(checklist.filter)
        filter.filter = tmpSlicers
        setSlicers(tmpSlicers)
        props.dispatch(editChecklist(checklist.id, tmpChecklistName, limit, sortColumn, tmpChecklistSortDirection,  kpi, checklist.automatic_update, JSON.stringify(filter), updateItems, props.redirect))
        onClose()
    }

    let cancelEdit = () => {
        let checklist = props.checklist
        setTmpChecklistName(checklist.name)
        setTmpChecklistLimit(checklist.limit)
        setTmpChecklistSortDirection(checklist.sort_direction)
        setTmpChecklistSortColumn(checklist.sort_column)
        setTmpChecklistHasLimit(checklist.limit != 1)
        setTmpChecklistHasKpi(checklist.selected_kpi == "")
        setTmpChecklistKpi(checklist.selected_kpi)
        onClose()
    }

    let hasChanged = () => {
        let checklist = props.checklist
        return (tmpChecklistName != checklist.name || tmpChecklistLimit != checklist.limit || tmpChecklistSortColumn != checklist.sort_column
                || tmpChecklistSortDirection != checklist.sort_direction || tmpChecklistHasLimit != (checklist.limit != -1) || tmpChecklistHasKpi != (checklist.selected_kpi != "") 
                || tmpChecklistKpi != checklist.selected_kpi
                || slicersHaveChanged())
    }

    let slicersHaveChanged = ()  => {
        if (slicers.length != tmpSlicers.length) return true
        for (let i = 0; i < slicers.length; i++){
            let exists = tmpSlicers.some((tmp: any) => {
                return slicerEquals(slicers[i], tmp)
            })
            if (!exists) return true
        }
        return false
    }

    let onClose = () => {
        props.onClose()
    }

    let numberColumns = JSON.parse(props.checklist.data_columns).filter((c: any) => c.ColumnType == DECIMAL_TYPE).map((c: any) => c.Name)
    let slicerColumns = getSlicerColumns()

    const bucket = props.buckets.get(props.checklist.bucket)

    if (bucket === undefined) {
        return <h2>Loading...</h2>
    }

    let columns: string[] = JSON.parse(props.checklist.data_columns).map((c: ChecklistColumn) => c.Name)
    let activeCategoryColumn = bucket.model.categorization_name
    let idColumn = getIdColumn(bucket.model.columns)

    let disableSave = (!tmpChecklistSortColumn && tmpChecklistHasLimit) || !hasChanged()
    
    let defaultKpi = numberColumns[0]
    return (
        <div className="edit-checklist-backdrop">
            <div className="edit-checklist-popup" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header sticky-modal-header">
                    <h4 className="modal-title">Edit Action list</h4>
                    <button type="button" className="close" ><span onClick={cancelEdit}>&times;</span></button>
                </div>
                <div className="modal-body">
                    <div>Name:</div>
                    <input className="form-control form-control-sm" placeholder="Name" value={tmpChecklistName} onChange={(e) => { setTmpChecklistName(e.target.value) } } />
                    <br/>
                    <div>Limit:</div>
                    <input type="checkbox" checked={tmpChecklistHasLimit} onChange={(e) => setTmpChecklistHasLimit(!tmpChecklistHasLimit)} />
                    <div style={{display: "inline-block"}} className={tmpChecklistHasLimit ? "" : "half-opacity"}>
                        <select className="form-control width-100px form-control-sm inline-block margin-left-5px" value={tmpChecklistSortDirection} onChange={(e) => setTmpChecklistSortDirection(e.target.value)} disabled={!tmpChecklistHasLimit}>
                            <option value={"desc"}>Top</option>
                            <option value={"asc"}>Bottom</option>
                        </select>
                        <select className="form-control width-100px form-control-sm margin-left-5px inline-block" value={tmpChecklistLimit} onChange={(e) => setTmpChecklistLimit(parseInt(e.target.value, 10))} disabled={!tmpChecklistHasLimit}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                            <option value={-1}>Infinite</option>
                        </select>
                        <select className="form-control form-control-sm inline-block margin-left-5px" value={tmpChecklistSortColumn} onChange={(e) => { setTmpChecklistSortColumn(e.target.value) }} style={{width: 235}} disabled={!tmpChecklistHasLimit}>
                            <option value={""} style={{display: "none"}}>Select column</option>
                            {
                                numberColumns.map((c: string, index: number) => {
                                    return <option value={c} key={index}>{c}</option>
                                })
                            }
                        </select> 
                    </div>
                    <div className='checklist-edit-slicers' style={{marginTop: 16}}>
                                <div>Slicer:</div>
                                <div className="dropdown inline-block vertical-align-top margin-bottom-10px">
                                    <button className="btn btn-sm btn-primary" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-expanded="false">
                                        <i className="fa fa-plus"></i>
                                    </button>
                                    <div className="dropdown-menu scrollable-dropdown-menu" aria-labelledby="dropdownMenu2" style={{paddingTop:0}}>
                                        <div className="text-center px-2 sticky-modal-header" style={{paddingTop:'6px'}}>
                                            <input type="text" value={addSlicerSearchStr} placeholder="Search columns" className="form-control form-control-sm" onChange={e => setAddSlicerSearchStr(e.target.value) } />
                                            <div className="dropdown-divider"></div>
                                        </div>
                                        {
                                            slicerColumns
                                                .filter((c: any) => addSlicerSearchStr === '' || c.name.toLowerCase().includes(addSlicerSearchStr.toLowerCase()))
                                                .map((c: any, index: any) => <button className="hover-cursor dropdown-item" type="button" onClick={() => columnClick(c, true)} key={index}>
                                                    {c.name === activeCategoryColumn ? <div className='d-flex flex-row align-items-center'>
                                                        <div>{c.name}</div>
                                                        <div className='pl-2'><ActiveCategoryIcon /></div>
                                                    </div> : <span>{c.name}</span>}
                                                </button>)
                                        }
                                    </div>
                                </div>
                                {
                                    tmpSlicers.map((item: any, index: number) => {
                                        return <SingleSlicer2
                                                slicer={item}
                                                index={index}
                                                key={index}
                                                openSlicer={(s: any) => openSlicer(s)}
                                                model={bucket?.model}
                                                removeSlicer={(slicer: any) => confirmRemoveSlicer(slicer)}
                                                idColumn={idColumn}
                                            />
                                    })
                                }
                    </div>
                    <div className='checklist-KPI' style={{marginTop: 16}}>
                                <div>Select KPI:</div>
                    <input type="checkbox" checked={tmpChecklistHasKpi} onChange={(e) => setTmpChecklistHasKpi(!tmpChecklistHasKpi)} />
                    <div style={{display: "inline-block"}} className={tmpChecklistHasKpi ? "" : "half-opacity"}>
                    <select className="dropdown selectkpi form-control width-180px inline-block margin-left-5px" value={tmpChecklistKpi} onChange={c => setTmpChecklistKpi(c.target.value)} disabled={!tmpChecklistHasKpi}>
                            <option value={""} style={{display: "none"}} ></option>
                            {
                                numberColumns.map((c: string, index: number) => {
                                    return <option value={c} key={index}>{c}</option>
                                }) 
                            }
                        </select> 
                    </div>
                    </div>
                </div>
                <div className="modal-footer sticky-modal-footer">
                    <div>
                        <button onClick={cancelEdit} type="button" className="btn btn-default margin-right-10px" >Close</button>
                        <button onClick={() => saveChecklist()} type="button" className="btn btn-primary margin-right-10px" disabled={disableSave}>Save</button>
                        <button onClick={() => saveChecklist(true)} type="button" className="btn btn-primary" disabled={disableSave}>Save and refresh</button>
                    </div>
                </div>

                <WarningAcceptDialog ref={warnRemoveSlicerRef} title="Delete filter" accept={() => removeSlicer(tmpRemoveSlicer)} >
                            <p>Are you sure that you want to delete this slicer?</p>
                </WarningAcceptDialog>
            </div>
            {
                slicerToEdit != null ?
                    <AddSlicerDialog2
                        slicer={slicerToEdit}
                        slicers={tmpSlicers}
                        show={showEditSlicer}
                        bucket={bucket}
                        saveSlicer={(s) => saveSlicer(s)}
                        onCancel={() => {setShowEditSlicer(false); setSlicerToEdit(undefined); setAddingSlicer(false)}}
                        columns={columns}
                    />
                    : <></>
            }

            
        </div>
    )
}

// @ts-ignore
EditChecklistDialog = connect(mapStateToProps)(EditChecklistDialog)

export default EditChecklistDialog
