import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import OrganizationAdministrationUsers from "./OrganizationAdministrationUsers"
import ReportingAdministration from "./ReportingAdministation"
import { getOrganizationUsers, getOrganizationLocations, getOrganizationItems, getOrganization } from "../../actions/OrganizationActions"
import { getAllPlans, getOrgMailBundles } from "../../actions/MailBundleActions"

const mapStateToProps = (state) => {
    return {
        orgID: state.User.info.organization,
        organization: state.Info.organization,
        organizationName: state.User.info.organization_name,
        orgUsers: state.Organization.users,
        orgAdmin: state.User.info,
        orgItems: state.Organization.items,
        organizationInfo: state.Organization.info,
        orgLocations: state.Organization.locations,
        orgMailBundles: state.Reporting.mailBundles,
        plans: state.Reporting.plans
    }
}

function OrganizationAdministration(props){
    const [tab, setTab] = useState(0)

    useEffect(() => {
        if(!props.orgID)return;
        props.dispatch(getOrganizationUsers(props.orgID))
        props.dispatch(getOrganizationLocations(props.orgID))
        props.dispatch(getOrganizationItems(props.orgID))
        props.dispatch(getOrganization(props.orgID))
        props.dispatch(getAllPlans())
        props.dispatch(getOrgMailBundles(props.orgID))
    }, [
        props.orgID,
    ])


    return (
        <div className="org-admin-wrapper position-relative">
            <div className="d-flex flex-column w-100">
                <h1 className="h2 mt-2 mr-4">
                    Manage {props.organizationName}
                </h1>
                {props.orgUsers && <div className="d-flex align-items-end" style={{width: 450}}>
                    <div
                        style={{zIndex: 99, userSelect: "none"}}
                        className={`${tab === 0 ? "border border-bottom-0 font-weight-bold py-2" : "text-secondary border btn-light abc-click py-1"} text-center w-100 rounded-top px-2 h5 mb-0`} 
                        onClick={() => setTab(0)}
                    >
                        <div className="mt-1">Users</div>
                    </div>
                    <div
                        style={{zIndex: 99, userSelect: "none"}} 
                        className={`${tab === 1 ? "border border-bottom-0 font-weight-bold py-2" : "text-secondary border btn-light abc-click py-1"} text-center w-100 px-2 rounded-top h5 mb-0`} 
                        onClick={() => setTab(1)}
                    >
                        <div className="mt-1">Subscriptions</div>
                    </div>
                </div>}
            </div>
            {tab === 0 && <OrganizationAdministrationUsers 
                {...props} 
            />}
            {tab === 1 && <ReportingAdministration 
                mailbundles={props.orgMailBundles} 
                plans={props.plans} 
                users={props.orgUsers} 
                orgId={props.orgID} 
                org={props.organizationInfo} 
                dispatch={props.dispatch}
                orgItems={props.orgItems}
                user={props.orgAdmin}
            />}

        </div>
    )
}

export default connect(mapStateToProps)(OrganizationAdministration)