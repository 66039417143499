import React, { useEffect, useRef, useState } from "react"
import { copyToClipboard } from "../../../helpers/GeneralHelpers"
import { notifySuccess } from "../../../helpers/NotificationManager"
import { notifyFailure } from '../../../helpers/NotificationManager'
import { is } from "../../../helpers/PermissionHelpers"
import { findNumberColumns, formatValue, getIdColumn, getShownDecimals, getTextColumns, monthValue } from "../../../helpers/ReportHelpers"
import DataGrid from "../../DataGrid"
import BaseDialog from "../../Dialogs/BaseDialog"
import InfiniteScrollOnScreen from "../../Generic/InfiniteScrollOnScreen"
import ShowIf from "../../Generic/ShowIf"
import RequirePermission from "../../RequirePermission"
import { getTrendsetsData, getDiffData } from '../../../actions/ReportActions'
import Progress from "../../Generic/Progress"
import {downloadReportDiffGridData } from '../../../actions/ReportActions'

export interface DiffViewProps {
    reportdata: any,
    selectedKpi: any,
    selectedCategory: any,
    slicers: any,
    setSelectedItems: (ids: any) => void,
    selectedItems: any[],
    selectedItemsLength: number,
    editMode: boolean,
    setData: any,

    settings: DiffViewSettings,
    setDiffSettings: (settings: DiffViewSettings) => void,
    promptCreateChecklist: () => void,
    diffdata: any,
    trendsetsdata: any,

    setBothTextColumns: any,
    setKpiView: any,
    hideDiffData: any,
    
    dispatch: any,
    last?: any,
    sndLast?: any,

    disableSave: any,
    getDisabledText: any,
    promptExitEditMode: any,
    enterEditMode: any,
}

export interface DiffViewSettings {
    selectedTextColumn: string,
    selectedSecondaryTextColumn: string,
    label1: string,
    label2: string,
    trendset1: string,
    trendset2: string,
    autoFit: any,
    showItems: any,
    selectedStartTrendset: any,
    selectedEndTrendset: any,
    selectedKpi: any,
}

const DiffView2 = (props: DiffViewProps) => {

    const [label1, setLabel1] = useState("")
    const [label2, setLabel2] = useState("")
    const [trendset1, setTrendset1] = useState("")
    const [trendset2, setTrendset2] = useState("")

    const [selectedTextColumn, setSelectedTextColumn] = useState<any>(null)
    const [selectedSecondaryTextColumn, setSelectedSecondaryTextColumn] = useState<any>(undefined)

    const [showItems, setShowItems] = useState<any>("All")
    const [offset, setOffset] = useState(0)

    const [autoFit, setAutoFit] = useState<any>()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hasMoreData, setHasMoreData] = useState(true)
    const [selectedStartTrendset, setSelectedStartTrendset] = useState<any>(null)
    const [selectedEndTrendset, setSelectedEndTrendset] = useState<any>(null)
    const [selectedKpi, setSelectedKpi] = useState<any>(null)
    const [sortParameter, setSortParameter] = useState(4)
    const [limitParameter, setLimitParameter] = useState(100)
    const [sortDirection, setSortDirection] = useState(true)

    let dataGrid = useRef<any>()

    // Load settings from props when mounted
    useEffect(() => {
        setLabel1(props.settings.label1 ?? label1)
        setLabel2(props.settings.label2 ?? label2)
        setSelectedTextColumn(props.settings.selectedTextColumn ?? getTextColumn())
        setSelectedSecondaryTextColumn(props.settings.selectedSecondaryTextColumn ?? getSecondaryColumn())
        setTrendset1(props.settings.trendset1 ?? trendset1)
        setTrendset2(props.settings.trendset2 ?? trendset2)
        setAutoFit(props.settings.autoFit ?? autoFit)
        setShowItems(props.settings.showItems ?? showItems)
        setSelectedStartTrendset(props.settings.selectedStartTrendset ?? selectedStartTrendset)
        setSelectedEndTrendset(props.settings.selectedEndTrendset ?? selectedEndTrendset)
        setSelectedKpi(props.selectedKpi ?? selectedKpi)
        let tmpDir = sortDirection
        if (props.last && props.sndLast) {
            if (props.last - props.sndLast > 0) {
                tmpDir = true;
            } else {
                tmpDir = false
            }
        }
        setSortDirection(tmpDir)
    }, [])

    // Propagate settings
    useEffect(() => {
        if (selectedTextColumn == null) return // Sometimes when first loading it activates with no settings
        props.setDiffSettings({
            selectedTextColumn,
            selectedSecondaryTextColumn,
            label1,
            label2,
            trendset1,
            trendset2,
            autoFit,
            showItems,
            selectedStartTrendset,
            selectedEndTrendset,
            selectedKpi,
        })
    }, [selectedTextColumn,
        selectedSecondaryTextColumn,
        label1,
        label2,
        trendset1,
        trendset2,
        autoFit,
        showItems,
        selectedStartTrendset, 
        selectedEndTrendset,
        selectedKpi])

    useEffect(() => {
        let actualLabel1 = selectedStartTrendset ? selectedStartTrendset.label : props.trendsetsdata[props.trendsetsdata.length - 2].label
        let actualLabel2 = selectedEndTrendset ? selectedEndTrendset.label : props.trendsetsdata[props.trendsetsdata.length - 1].label

        setLabel1(actualLabel1)
        setLabel2(actualLabel2)

        setTrendset1(props.trendsetsdata.findLast((xy: { label: any }) => xy.label === actualLabel1).dataset_id)
        setTrendset2(props.trendsetsdata.findLast((xy: { label: any }) => xy.label === actualLabel2).dataset_id)
    }, [props.trendsetsdata])

    // If the data should update
    useEffect(() => {
        updateData()
    }, [props.selectedKpi, props.selectedCategory, sortParameter, sortDirection, 
        offset, showItems, selectedStartTrendset, selectedEndTrendset, selectedTextColumn, 
        selectedSecondaryTextColumn, trendset1, trendset2, props.reportdata.report.columns,
        props.slicers, props.settings, limitParameter, selectedKpi])

    useEffect(() => {
        //props.setBothTextColumns(selectedTextColumn, selectedSecondaryTextColumn)

    }, [selectedTextColumn, selectedSecondaryTextColumn])

    let setBothTextColumns = (c1: any, c2: any) => {
        setSelectedTextColumn(c1)
        setSelectedSecondaryTextColumn(c2)
    }

    // If make sure that the text columns exist
    useEffect(() => {
        let columns = getTextColumns(JSON.parse(props.reportdata.report.columns), props.reportdata.info.model.columns)
        if(selectedTextColumn && !columns.includes(selectedTextColumn)){
            if (columns.length > 0) {
                setSelectedTextColumn(columns[0])
            } else {
                setSelectedTextColumn(null)
            }
        }
        if (selectedSecondaryTextColumn && !columns.includes(selectedSecondaryTextColumn)){
            setSelectedSecondaryTextColumn(null)
        }
    }, [props.reportdata.report.columns])

    let getStatusBox = (status: any, statusNum: any) => {
        if (statusNum === -1) {
            return <div className="oldItem" title={"Is no longer on the list"}>GONE</div>
        } else if (statusNum === 1) {
            return <div className="newItem" title={`Wasn't on the list in ${label1}`}>NEW</div>
        }
        return <span></span>
    }

    let copyReportDataToClipboard = () => {
        copyToClipboard(dataGrid.current.getDiffHTML(false, true))
        const rowCount = props.selectedItemsLength > 0 ? props.selectedItemsLength : props.diffdata.values.length;
        notifySuccess(rowCount + ' rows has been copied to the clipboard')
    }

    let deselectAll = () => {
        dataGrid.current.deselectAll()
    }

    let getTextColumn = () => {
        let reportdata = props.reportdata
        let columns = reportdata.report.columns ? JSON.parse(reportdata.report.columns) : []

        let res = ""
        if (selectedTextColumn !== null) {
            res = selectedTextColumn
        } else {
            const viewSettings = JSON.parse(reportdata.report.view_settings)
            if (!viewSettings.trend) viewSettings.trend = {}
            const editModeColumn = viewSettings.trend.diff ? viewSettings.trend.diff.graph_column : ""
            const defaultBucketColumn =  props.reportdata && props.reportdata.info ? props.reportdata.info.setup.id_description_column : null
            if (editModeColumn && editModeColumn !== "") {
                res = editModeColumn
            } else if (defaultBucketColumn && defaultBucketColumn !== "") {
                res = defaultBucketColumn
            } else {
                res = getTextColumns(columns, reportdata.info.model.columns)[0]
            }
        }
        const index = columns.indexOf(res)
        if (index === -1) { // if we have a column that is no longer in the report because of bucket change
            res = getTextColumns(columns, reportdata.info.model.columns)[0]
        }
        if (res === undefined) res = "-"

        return res
    }

    let getSecondaryColumn = () => {
        let reportdata = props.reportdata
        if (selectedSecondaryTextColumn !== undefined) {
            return selectedSecondaryTextColumn
        } else {
            const viewSettings = JSON.parse(reportdata.report.view_settings)
            if (!viewSettings.trend) viewSettings.trend = {}
            const editModeColumn = viewSettings.trend.diff ? viewSettings.trend.diff.graph_secondary_column : ""
            if (editModeColumn && editModeColumn !== "") {
                return editModeColumn
            }
        }
        return null
    }

    let getDirection = () => {
        return sortDirection === true ? 0 : -1
    }


    //Validate selected columns for existance in trendset!
    let validateColumns = () => {
        let trendsetsdata = props.trendsetsdata
        let dataset_start = selectedStartTrendset !== null ? selectedStartTrendset.dataset_id : trendsetsdata[trendsetsdata.length - 2].dataset_id;
        let dataset_end = selectedEndTrendset !== null ? selectedEndTrendset.dataset_id : trendsetsdata[trendsetsdata.length - 1].dataset_id;

        let datasets = []
        for(let i = 0; i < trendsetsdata.length; i++){
            if (trendsetsdata[i].dataset_id == dataset_start  || trendsetsdata[i].dataset_id == dataset_end){
                datasets.push(trendsetsdata[i])
            }
        }

        let textColumn = selectedTextColumn !== null ? selectedTextColumn : getTextColumn()
        let secondaryTextColumn = getSecondaryColumn()
        
        let columns = [textColumn]

        if (selectedKpi) {
            columns.push(selectedKpi)
        }

        if (secondaryTextColumn){
            columns.push(secondaryTextColumn)
        }
        if (selectedSecondaryTextColumn && selectedSecondaryTextColumn !== secondaryTextColumn){
            columns.push(selectedSecondaryTextColumn)
        }

        if(props.reportdata.report.sort_column){
            columns.push(props.reportdata.report.sort_column)
        }

        let errors:string[] = []
        for(let i = 0; i < datasets.length; i++){
            for(let j = 0; j < columns.length; j++) {
                let query_column = columns[j];
                let column_exist_in_dataset = false

                for(let k = 0; k < datasets[i].columns.length; k++){
                    let dataset_column = datasets[i].columns[k];

                    if(dataset_column.name === query_column){
                        column_exist_in_dataset = true
                        k = datasets[i].columns.length
                    }
                }
                if(!column_exist_in_dataset) {
                    errors.push("Column: " +  query_column + " doesn't exist in " + datasets[i].label)
                }

            }
        }

        return errors
    }


    let updateData = () => {
        let errors = validateColumns()

        if(errors.length > 0){
            errors.map( error => notifyFailure(error, error, false, true))
            console.log(errors)
        }

        let trendsetsdata = props.trendsetsdata

        let useMvFilter = showItems !== 'All' ? true : false
        let mvFilter = parseInt(showItems, 10)
        let textColumn = selectedTextColumn !== null ? selectedTextColumn : getTextColumn()
        let secondaryTextColumn = getSecondaryColumn()
        let { dispatch } = props
        let data = {
            dataset_start: selectedStartTrendset !== null ? selectedStartTrendset.dataset_id : trendsetsdata[trendsetsdata.length - 2].dataset_id,
            dataset_end: selectedEndTrendset !== null ? selectedEndTrendset.dataset_id : trendsetsdata[trendsetsdata.length - 1].dataset_id,
            filter: getCustomFilter(props.reportdata.report, props.selectedCategory, props.reportdata.info.model.categorization_name, props.slicers), // using custom filter method
            sort_column: props.reportdata.report.sort_column,
            sort_direction: props.reportdata.report.sort_direction,
            limit: props.reportdata.report.limit,
            diff_column: selectedKpi,
            description_column: textColumn,
            secondary_description_column: secondaryTextColumn ? secondaryTextColumn : "-",
            selected_secondary_text_column: selectedSecondaryTextColumn ? "-" : selectedSecondaryTextColumn,

            sort_parameter: sortParameter,
            sort_parameter_direction: getDirection(),
            limit_parameter: limitParameter,
            offset_parameter: offset,

            use_movement_filter: useMvFilter,
            movement_filter: mvFilter,

            start_limit: -1,
            end_limit: -1,
            dataset_start_value: selectedStartTrendset ? selectedStartTrendset.label : label1,
            dataset_end_value: selectedEndTrendset ? selectedEndTrendset.label : label2,
        }
        if (trendset2 !== "") {
            dispatch(getDiffData(props.reportdata.info.id, props.reportdata.report.report_id, data))
            dispatch(getTrendsetsData(props.reportdata.info.id, props.reportdata.report.report_id, props.reportdata.report.report_id))
        }
    }

    let setSort = (parameter: any, column: any) => {
        const selectedTextColumn = getTextColumn()
        if ((selectedTextColumn === "-" || selectedTextColumn === null) && (parameter === 1 && column === 1)) return;
        let direction = sortDirection
        if (sortParameter === parameter) {
            direction = !direction
        } else {
            direction = true
        }

        // if the user has used pressed 'Load more data'-button and then starts sorting
        let limit = limitParameter
        let offset2 = offset
        if (offset > 99) {
            limit += offset
            offset2 = 0
        }
        setSortParameter(parameter)
        setSortDirection(direction)
        //setChosenSortColumn(column) State not used?
        setLimitParameter(limit)
        setOffset(offset2)
    }

    //This function serves as an adapter from the datagrid way of sorting to the legacy diff way of sorting with sortParameter. 
    let setSortColumn = (column: any) => {
        const idColumn = getIdColumn(props.reportdata.info.model.columns)
        let selectedTextColumn = getTextColumn()
        const secondarySelectedTextColumn = getSecondaryColumn()
        const statusColumn = "Status"
        const diffColumn = "Changes"

        let labelUno: any = selectedStartTrendset ? selectedStartTrendset.label : label1
        let labelDuo: any = selectedEndTrendset ? selectedEndTrendset.label : label2

        switch (column.name) {
            case idColumn: setSort(0, 0); break
            case selectedTextColumn: setSort(1, 1); break
            case secondarySelectedTextColumn: setSort(6, 6); break
            case statusColumn: setSort(5, 2); break
            case labelUno: setSort(2, 3); break
            case labelDuo: setSort(3, 4); break
            case diffColumn: setSort(4, 5); break
            default: break
        }
    }

    if (props.diffdata == null){
        return <span></span>
    }

    let itemSelection = (ids: any) => {
        props.setSelectedItems(ids)
    }

    let fetchMoreData = () => {
        //setOffset(offset + 100);
        setLimitParameter(limitParameter + 100)
    }

    let getProgressBarJSX = (absmax: number) => (value: any, rawValue: number) => {
        //absmax = absolute maximum
        return <Progress type={"margin-right-0px"} current={Math.abs(rawValue)} min={0} max={absmax} reverse={rawValue < 0} />
    }
    


    let kpis = JSON.parse(props.reportdata.report.kpis)

    let showDecimals = props.selectedKpi !== null ? getShownDecimals(kpis, props.selectedKpi) : false
    let textColumns = getTextColumns(JSON.parse(props.reportdata.report.columns), props.reportdata.info.model.columns)
    let idColumn = getIdColumn(props.reportdata.info.model.columns)
    let bucketId = props.reportdata.info.id
    let numCols = findNumberColumns(props.reportdata.info.model, JSON.parse(props.reportdata.report.columns))

    let maxDiff = props.diffdata ? props.diffdata.max_diff : 0

    let actualLabel1 = selectedStartTrendset ? selectedStartTrendset.label : label1
    let actualLabel2 = selectedEndTrendset ? selectedEndTrendset.label : label2
    let dataGridColumns = [
        { name: idColumn, index: 0, type: "id", headerClassName: "width-200-px" },
        { name: selectedTextColumn, index: 1, type: "text", headerClassName: "width-200-px" },
        { name: "Status", index: 2, type: "text", disableAutoFit: true, colgroupClassName: "width-80px", wrapper: getStatusBox },
        { name: actualLabel1, index: 3, type: "decimal", disableAutoFit: true, colgroupClassName: "width-150px" },
        { name: actualLabel2, index: 4, type: "decimal", disableAutoFit: true, colgroupClassName: "width-150px" },
        { name: `Changes`, index: 5, type: "decimal", disableAutoFit: true, colgroupClassName: "width-150px", isTotal: true },
        { name: "", index: 5, type: "decimal", mergeHeaderWithPrevious: true, wrapper: getProgressBarJSX(maxDiff) },
    ]

    if (selectedSecondaryTextColumn) {
        dataGridColumns.splice(2, 0, { name: selectedSecondaryTextColumn, index: 6, type: "text", headerClassName: "width-200-px" })
    }

    let diffdata = props.diffdata
    if(diffdata.values && !diffdata.values[0].hasOwnProperty('is_total')){
        const totalRow = { is_total: true, item_id: diffdata.id_total, description: diffdata.description_total, secondary_description: diffdata.secondary_description_total, start_value: diffdata.start_total, end_value: diffdata.end_total, status: 3, diff: (diffdata.end_total - diffdata.start_total) }
        diffdata.values.unshift(totalRow)
    }

    let dataGridRows = diffdata.values ? diffdata.values.map((val: any) => {
        return {
            data: [
                val.item_id,
                val.description,
                val.status,
                monthValue("start", val),
                monthValue("end", val),
                val.diff,
                val.secondary_description
            ],
            id: val.item_id
        }
    }) : []

    let downloadGridDataExcel = () => {
        downloadGridDataCommon('excel')
    }

    let downloadGridData = () => {
        downloadGridDataCommon('csv')
    }
    let downloadGridDataCommon= (type: string) => {

        let errors = validateColumns()
        if(errors.length > 0){
            errors.map( error => notifyFailure(error, error, false, true))
            return; //data will be empty.
        }

        let { dispatch } = props
        
        let trendsetsdata = props.trendsetsdata

        let useMvFilter = showItems !== 'All' ? true : false
        let mvFilter = parseInt(showItems, 10)
        let textColumn = selectedTextColumn !== null ? selectedTextColumn : getTextColumn()
        let secondaryTextColumn = getSecondaryColumn()
        const filter = getCustomFilter(props.reportdata.report, props.selectedCategory, props.reportdata.info.model.categorization_name, props.slicers) // using custom filter method
        
        if(props.selectedItemsLength > 0){
            const idColumn = getIdColumn(props.reportdata.info.model.columns);
            const slicer = {
                column: idColumn,
                target_values: props.selectedItems,
                compare_type: 1
            }
            filter.push(slicer)
        }

        let data = {
            name: selectedKpi,
            type: type,
            query: {
                dataset_start: selectedStartTrendset !== null ? selectedStartTrendset.dataset_id : trendsetsdata[trendsetsdata.length - 2].dataset_id,
                dataset_end: selectedEndTrendset !== null ? selectedEndTrendset.dataset_id : trendsetsdata[trendsetsdata.length - 1].dataset_id,
                filter: filter,
                sort_column: props.reportdata.report.sort_column,
                sort_direction: props.reportdata.report.sort_direction,
                limit: props.reportdata.report.limit,
                diff_column: selectedKpi,
                description_column: textColumn,
                secondary_description_column: secondaryTextColumn ? secondaryTextColumn : "-",
                selected_secondary_text_column: selectedSecondaryTextColumn == secondaryTextColumn ? "-" : selectedSecondaryTextColumn,

                sort_parameter: sortParameter,
                sort_parameter_direction: getDirection(),
                limit_parameter: props.diffdata.rows,
                offset_parameter: offset,

                use_movement_filter: useMvFilter,
                movement_filter: mvFilter,

                start_limit: -1,
                end_limit: -1,

                dataset_start_value: actualLabel1,
                dataset_end_value: actualLabel2,
            }
        }
        notifySuccess("You will get a notification when your download is ready")
        dispatch(downloadReportDiffGridData(bucketId, data))
    }
    return (
        <div className="">
            <ShowIf if={props.trendsetsdata.length < 2}>
                <p className="alert alert-danger">
                    Diffview is disabled! You need to have at least two datasets in order to use Trend properly.
                </p>
            </ShowIf>
            <ShowIf if={kpis.length > 0}>
                <DiffControls
                    label1={label1}
                    label2={label2}
                    totalStartVal={diffdata.start_total}
                    totalEndVal={diffdata.end_total}
                    showDecimals={showDecimals}
                    setShowType={(type: any) => {setShowItems(type); setOffset(0)}}
                    type={showItems}
                    trendsets={props.trendsetsdata}
                    setStartTrendset={(t: any) => setSelectedStartTrendset(t)}
                    setEndTrendset={(t: any) => setSelectedEndTrendset(t)}
                    setTextColumns={(t: any, s: any) => setBothTextColumns(t, s)}
                    columns={textColumns}
                    selectedTextColumn={selectedTextColumn}
                    selectedSecondaryTextColumn={selectedSecondaryTextColumn}
                    setSelectedKpi={(e: any) => setSelectedKpi(e)}
                    selectedKpi={selectedKpi}
                    kpis={kpis}
                    setKpiView={(kpi: any) => props.setKpiView(kpi)}
                    idColumn={idColumn}
                    selectedCategory={props.selectedCategory}
                    goBack={() => {}}
                    deselectAll={deselectAll}
                    selectedItemsLength={props.selectedItemsLength}
                    copyGrid={() => copyReportDataToClipboard()}
                    hide={props.hideDiffData}
                    promptCreateChecklist={props.promptCreateChecklist}
                    editMode={props.editMode}
                    disableSave={props.disableSave}
                    getDisabledText={props.getDisabledText}
                    promptExitEditMode={props.promptExitEditMode}
                    enterEditMode={props.enterEditMode}
                    updateData={updateData}
                    downloadGridData={downloadGridData}
                    downloadGridDataExcel={downloadGridDataExcel}
                    downloadGridDataCommon={(t: any) => downloadGridDataCommon(t)}
                    diffdata={props.diffdata}
                    numcolumns={numCols}
                />

                <div className='px-3 pb-1'>
                    <DataGrid
                        ref={dataGrid}
                        id={0}
                        bucketId={bucketId}
                        columns={dataGridColumns}
                        rows={dataGridRows}
                        onSort={(col: any, direction: any) => setSortColumn(col)}
                        showDecimals={showDecimals}
                        onSelect={(ids: any) => itemSelection(ids)}
                        autoFitArr={autoFit}
                        setAutoFitArr={(arr: any) => setAutoFit(arr)}
                        sticky={-2}
                        showOpenButton
                        className="sum-sheet-table"
                        isDiff
                        activeCategoryColumn={props.reportdata.info.model.categorization_name}
                    />
                    { dataGridRows.length === 0 ? <div className="alert alert-info">No data to display</div> : null }
                </div>
                <InfiniteScrollOnScreen hasMore={hasMoreData} next={() => fetchMoreData()} loadingText={<span>...</span>}/>
            </ShowIf>
        </div>
    )
}


class DiffControls extends React.Component<any> {
    state = {
        selectedStartTrendset: this.props.trendsets[this.props.trendsets.length - 2],
        selectedEndTrendset: this.props.trendsets[this.props.trendsets.length - 1],
        selectedTextColumn: this.props.selectedTextColumn,
        selectedSecondaryTextColumn: this.props.selectedSecondaryTextColumn,
        selectedKpi: this.props.selectedKpi,
    }

    render() {
        let diff = this.props.totalEndVal - this.props.totalStartVal
        let setType = (val: any) => {
            this.props.setShowType(val)
        }

        let setStartTrendset = (e: any) => {
            const val = JSON.parse(e.target.value)
            this.setState({ selectedStartTrendset: val }, () => {
                this.props.setStartTrendset(val ?? this.props.trendsets[this.props.trendsets.length - 2])
            })
        }
        let setEndTrendset = (e: any) => {
            const val = JSON.parse(e.target.value)
            this.setState({ selectedEndTrendset: val }, () => {
                this.props.setEndTrendset(val ?? this.props.trendsets[this.props.trendsets.length - 1])
            })
        }

        let setTextColumn = (e: any) => {
            this.setState({ selectedTextColumn: e.target.value })
        }

        let setSecondaryTextColumn = (c: any) => {
            this.setState({ selectedSecondaryTextColumn: c })
        }

        let saveTextColumns = () => {
            this.props.setTextColumns(this.state.selectedTextColumn, this.state.selectedSecondaryTextColumn)
            //@ts-ignore
            this.refs.textColumnSelect.hide()
        }

        let setSelectedKpi = (e: any) => {
            const val = JSON.parse(e.target.value)
            this.setState({ selectedKpi: val }, () => {
                this.props.setSelectedKpi(val)
                this.props.updateData()
            })
        }

        let saveKPI = () => {
            //@ts-ignore
            this.refs.KPISelect.hide()
            this.props.setKpiView(this.state.selectedKpi)
        }

//        let category = () => {
//            if (this.props.selectedCategory) {
//                return <span> / {this.props.selectedCategory} </span>
//            }
//        }

        let openDialog = () => {
            this.setState({selectedTextColumn: this.props.selectedTextColumn,
                selectedSecondaryTextColumn: this.props.selectedSecondaryTextColumn},
                () => {
                    //@ts-ignore
                    this.refs.textColumnSelect.show()
                })
        }

        let getEditButton = () => {
                        return this.props.editMode ?
                                <React.Fragment>
                                    <button type="button"
                                        disabled={this.props.disableSave}
                                        title={this.props.getDisabledText()}
                                        className="btn btn-danger btn-sm"
                                        onClick={() => this.props.promptExitEditMode()} >Edit mode: ON <i className="fa fa-cog"></i>
                                    </button>
                                </React.Fragment>
                                : <React.Fragment>
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.props.enterEditMode()}><i className="fa fa-cog"></i></button>
                                </React.Fragment>
        }

        const styles = { color: "#777", margin: 0 }

        let selectedStartIndex = this.props.trendsets.length - 2
        if(this.props.label1) {
            selectedStartIndex = this.props.trendsets.findIndex((xy: { label: any }) => xy.label === this.props.label1)
        }

        let selectedEndIndex = this.props.trendsets.length - 1
        if(this.props.label2) {
            selectedEndIndex = this.props.trendsets.findIndex((xy: { label: any }) => xy.label === this.props.label2)
        }
        
        //no dupes
        let filteredKpis = this.props.kpis.filter((value:any, index:any, array:any) => array.findIndex((val: { column: any }) => val.column == value.column) === index)

        let optionitems = filteredKpis.map((k: any, index: any) => {
            if (k.column === this.props.idColumn) {
                return <button key={index} className="no-show" ></button>
            }
            else if (k.column === this.props.selectedKpi) {
                return (
                <button key={index} className="dropdown-item " style={{ fontWeight: "bold" }} onClick={() => {this.props.setSelectedKpi(k.column)}} > {k.column}</button>
                )
            }
            else {
                return (
                    <button key={index} className="dropdown-item " onClick={() => {this.props.setSelectedKpi(k.column)}}> {k.column}</button>
                )
            }
        })

        let allOptItems = this.props.numcolumns.map((n: any, i: any) => {
            if (n === this.props.selectedKpi) {
                return <button key={i} className="dropdown-item" style={{fontSize: "smaller", fontWeight: "bold"}} onClick={() => {this.props.setSelectedKpi(n)}}>{n}</button>

            } else {
                return <button key={i} className="dropdown-item" style={{fontSize: "smaller"}} onClick={() => {this.props.setSelectedKpi(n)}}>{n}</button>
            }
        })                   
        
        return (
            <div>
                <div className='d-flex flex-column'>
                    <div className='border-bottom p-3'>
                        <div className='d-flex flex-row justify-content-between'>
                            <div><h3 style={styles} className=''>Difference View </h3></div>
                            <div>
                                <span className="fa fa-times close-button" onClick={_ => this.props.hide()} />
                            </div>
                        </div>
                    </div>
                    <div className='border-bottom p-3'>
                        <div className='d-flex flex-column '>
                            <div className='pb-1 '>
                                <div className="d-flex">
                                <h2 style={styles}>{this.props.selectedKpi}</h2>
                                    <div className=" margin-left-10px">
                                        <div className="btn-group" role="group">
                                            <button className="btn btn-default btn-sm dropdown-toggle show" id="dropdownMenu" data-toggle="dropdown" aria-expanded="false" name="selectKPI"   >
                                            </button>
                                        <div className="dropdown-menu scrollable-dropdown-menu " aria-labelledby="dropdownMenu scrollable-dropdown-menu" >
                                            {optionitems}
                                            <div className="dropdown-divider"></div>
                                            {allOptItems}
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ fontWeight: "bold" }} className='font-size-16px'>
                                <span className=''>From {this.props.label1 ? this.props.label1 : "last month"} to {this.props.label2 ? this.props.label2 : "this month"}: </span>
                                <span className={`pl-3`} >{formatValue(true, diff, this.props.showDecimals)}</span>
                            </div>
                        </div>
                    </div>
                    <div className='p-3 d-flex flex-row justify-content-between align-items-center'>
                        <div className='d-flex flex-row'>
                            <div className='d-flex flex-row align-items-center pr-4'>
                                <div className='pr-2'>Status:</div>
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    {[{ name: "All", value: "All" }, { name: "Newcomers", value: "1" }, { name: "Gone", value: "-1" }, { name: "Still in the Insight", value: "0" }].map((s, i) =>
                                        <button key={i} type="button" onClick={_ => setType(s.value)} className={`${this.props.type === s.value ? "active" : ""} px-3 btn btn-sm btn-default`}>
                                            {s.name}
                                        </button>)}
                                </div>
                            </div>
                            <div className='d-flex flex-row align-items-center'>
                                <div className='pr-2'>Track changes from </div>
                                <div>
                                    <div className="form-inline">
                                        <select className="form-control form-control-sm" value={this.props.label1 ? JSON.stringify(this.props.trendsets.findLast((xy: { label: any }) => xy.label === this.props.label1)):  JSON.stringify(this.state.selectedStartTrendset)} onChange={setStartTrendset}>
                                            {
                                                this.props.trendsets.map((t: any, index: any) => {
                                                    if (this.props.trendsets.length === index + 1 || index > (selectedEndIndex  - 1)) {
                                                        return <option key={index} className="no-show"></option>
                                                    } else {
                                                        return (
                                                            <option key={index} value={JSON.stringify(t)}>{t.label}</option>
                                                        )
                                                    }
                                                })
                                            }
                                        </select>
                                        &nbsp;to&nbsp;
                                        <select className="form-control form-control-sm" value={this.props.label2 ? JSON.stringify(this.props.trendsets.findLast((xy: { label: any }) => xy.label === this.props.label2)):  JSON.stringify(this.state.selectedEndTrendset)} onChange={setEndTrendset}>
                                            {
                                                this.props.trendsets.map((t: any, index: any) => {
                                                    if (this.props.trendsets.length === index || index < (selectedStartIndex + 1)) {
                                                        return <option key={index} className="no-show"></option>
                                                    } else {
                                                        return (
                                                            <option key={index} value={JSON.stringify(t)}>{t.label}</option>
                                                        )
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flew-row'>
                            <div>
                                <ShowIf if={this.props.selectedItemsLength > 0}>
                                    <button className="btn btn-default btn-sm margin-right-5px" onClick={this.props.deselectAll}>Deselect items ({this.props.selectedItemsLength})</button>
                                </ShowIf>
                            </div>
                            <div><div className="btn-group margin-right-5px">
                                <button className="btn btn-default btn-sm" onClick={() => this.props.copyGrid()}><i className="fa fa-copy"></i>{this.props.selectedItemsLength > 0 ? ` (${this.props.selectedItemsLength})` : ""}</button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <ShowIf if={ this.props.diffdata.rows< 1000000}> 
                                    <li className="abc-click" onClick={this.props.downloadGridDataExcel}><span>Download data (Excel)</span></li>
                                    </ShowIf>
                                    <ShowIf if={this.props.diffdata.rows >= 1000000}>
                                        <li><span title="Data set is too big"><i>Download data (Excel)</i></span></li>
                                    </ShowIf>
                                    <li className="abc-click" onClick={this.props.downloadGridData}><span> Download data (CSV)</span></li>
                                </ul>
                                <button title="Download" type="button" className="btn btn-default btn-sm " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="fa fa-download"></i>
                                    {this.props.selectedItemsLength > 0 ? ` (${this.props.selectedItemsLength})` : ""}
                                    <span className="sr-only">Toggle Dropdown</span>
                                </button>
                            </div>
                            </div>
                            <div><button onClick={() => openDialog()} className="btn btn-default btn-sm margin-right-5px"><i className="fa fa-columns"></i></button></div>
                            <div>{getEditButton()}</div>
                            <div>
                                <RequirePermission perms={is.checklistsUser}>
                                    <button title={this.props.selectedItemsLength === 0 ? "Select rows by ctrl+click" : ""} disabled={this.props.selectedItemsLength === 0} onClick={_ => this.props.promptCreateChecklist()} className='btn btn-sm btn-primary ml-2'>
                                        <span className="fa fa-check margin-right-5px" /> Create Action list {this.props.selectedItemsLength > 0 && <span>({this.props.selectedItemsLength})</span>}
                                    </button>
                                </RequirePermission>
                            </div>
                        </div>
                    </div>
                </div>
                {/*@ts-ignore*/}
                <SelectionDialog ref="textColumnSelect" disableDataDismiss={true} closeHandler={() => this.refs.textColumnSelect.hide()} disabled={this.state.selectedTextColumn === "-"} title="Select description column" save={() => saveTextColumns()} >
                    <label>Choose column: </label>
                    <div className="form-inline">
                        <select className="form-control width-100-p" value={this.state.selectedTextColumn ? this.state.selectedTextColumn : ""} onChange={setTextColumn}>
                            {
                                this.props.columns.map((c: any, index: any) => {
                                    return (
                                        <option key={index} value={c}>{c}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <hr />
                    <label>Choose additional column: </label>
                    <div className="form-inline row">
                        <div className={`${this.state.selectedSecondaryTextColumn ? "col-10" : "col-12"}`}>
                            <select className={`form-control width-100-p`} value={this.state.selectedSecondaryTextColumn ? this.state.selectedSecondaryTextColumn : ""} onChange={e => setSecondaryTextColumn(e.target.value)}>
                                <option value={""} hidden disabled>
                                    Select column
                                </option>
                                {
                                    this.props.columns.map((c: any, index: any) => {
                                        return (
                                            <option key={index} value={c}>{c}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <ShowIf if={this.state.selectedSecondaryTextColumn}>
                            <div className="col-2">
                                <button className="btn btn-default margin-left-10px float-right" title="Remove additional column" onClick={() => setSecondaryTextColumn(null)}>
                                    <span className="fa fa-trash" />
                                </button>
                            </div>
                        </ShowIf>
                    </div>
                </SelectionDialog>
                {/* // TODO: Delete if not used anymore */}
                <SelectionDialog ref="KPISelect" title="Select KPI Diff" save={() => saveKPI()}>
                    <label>Choose KPI: </label>
                    <div className="form-inline">
                        <select className="form-control width-100-p" value={JSON.stringify(this.state.selectedKpi)} onChange={(e) => setSelectedKpi(e)}>
                            {
                                this.props.kpis.map((k: any, index: any) => {
                                    if (k.column === this.props.idColumn) {
                                        return <option key={index} className="no-show"></option>
                                    } else {
                                        return (
                                            <option key={index} value={JSON.stringify(k.column)}>{k.column}</option>
                                        )
                                    }
                                })
                            }
                        </select>
                    </div>
                </SelectionDialog>
            </div>
        )
    }
}

class SelectionDialog extends BaseDialog {
    getButtons() {
        return (
            <div>
                <button onClick={this.props.closeHandler} type="button" className="btn btn-default margin-right-10px">Cancel</button>
                <button type="button" className="btn btn-primary" title={this.props.disabled ? "This Insight contains no text columns. Add text columns to select them here." : ""} disabled={this.props.disabled} onClick={() => this.props.save()}>Select</button>
            </div>
        )
    }
}

let getCustomFilter = (report: any, c: any, mastername: any, slicers: any) => {
    let filters = JSON.parse(report.filter)
    if (report.fixed_slicer !== '') {
        // TODO - lav support for værdier der er brugt i den faste slicer
    }
    if (c !== null && c !== undefined) {
        filters.push({ column: mastername, compare_type: 1, target_values: [c] })
    }

    if (slicers) {
        slicers.forEach((item: any, index: any) => {
            filters.push(item)
        })
    }

    return filters
}

export default DiffView2
