import { apiGet, apiPost, apiDelete, apiPut } from '../../helpers/ApiHelpers'
import { updatedChecklists, getChecklistsStatus, clearChecklistsProps } from '../../actions/StatusActions'
import { gotChecklist, gotChecklistItems, gotChecklistComments, getChecklistItems, updatedChecklist, gotPastedChecklistComments, gotChecklistItemData, updatedPastedComment, gotAllPastedChecklistComments, getLayouts, gotLayouts, checklistNotFound, checklistNotAccessible, newLayout, updateChecklistItems, gotChecklistCategoryItems, resetChecklistItems } from '../../actions/ChecklistActions'
import { packAction } from '../../actions/ActionTypes'
import * as ActionTypes from '../../actions/ActionTypes'
import { notifySuccess } from '../../helpers/NotificationManager'
import { notifyApiFailure } from '../../helpers/ErrorHelpers'
import { getHomeInfo } from '../../actions/InfoActions'
import { gotActionTemplates } from '../../actions/TemplateActions'

const ChecklistApiMiddleware = ({dispatch, getState}) => (next) => (action) => {
    next(action)
    let url = undefined
    let data = undefined
    switch (action.type) {
        case ActionTypes.UPDATE_CHECKLISTS:
            url = 'checklists/update/'
            data = {checklistsData: action.payload}
            apiPut(url, data, checklists => {
                notifySuccess("Changes to Action list saved")
                dispatch(updatedChecklists(checklists))
            }, e => notifyApiFailure(`updating checklists`, "PUT", url, data))
            break
        case ActionTypes.EDIT_CHECKLIST:
            url = `checklists/checklist/edit/${action.payload.id}`
            data = action.payload
            apiPut(url, data, id => {
                notifySuccess("Changes to Action list saved")
                
                if (action.payload.update_items){
                    dispatch(updateChecklistItems(id, action.payload.redirect))
                } else {
                    updatedChecklist(dispatch, id)
                }
            }, e => notifyApiFailure('saving edited Action list', 'PUT', url, data, e.status))
            break
        case ActionTypes.UPDATE_CHECKLIST:
            url = `checklists/checklist/update/${action.payload.id}/`
            data = action.payload
            apiPut(url, data, checklist => {
                notifySuccess("Changes to Action list saved")
                updatedChecklist(dispatch, checklist.id)

                if(action.payload.id == checklist.id) {
                   checklistResult(dispatch, {payload: checklist.id})(checklist)
                   dispatch(packAction(ActionTypes.GET_FOLLOWINGS))
                   notifySuccess("The Action list has been updated.")
                   //window.location.hash = `/checklist/${checklist.id}`
                }
                if (action.cb) {
                    action.cb()
                }

            }, e => notifyApiFailure(`saving checklist`, 'PUT', url, data, e.status))
            break
        case ActionTypes.SAVE_MULT_LAYOUTS: 
            url = 'checklists/multi/layout'
            data = action.payload
            apiPut(url, data, checklists => {
                notifySuccess("Changes to Action lists saved")
                dispatch(updatedChecklists(checklists))
                dispatch(clearChecklistsProps(checklists))
            }, e => notifyApiFailure('changing multiple layouts', 'PUT', url, data))
            break
        case ActionTypes.SAVE_CHECKLIST_LAYOUT:
            url = `checklists/checklist/layout/${action.payload.id}/`
            data = { layout: action.payload.layout, deleted_indexes: action.payload.deleted_indexes, id: action.payload.id, changed_statuses: action.payload.changed_statuses}
            data = action.payload
            apiPut(url, data, checklist => {
                notifySuccess("Action list layout saved")
                updatedChecklist(dispatch, checklist.id)
                action.payload.cb()
            }, e => notifyApiFailure(`saving layout`, 'PUT', url, data, e.status))
            break
        case ActionTypes.DELETE_CHECKLIST:
            url = `checklists/checklist/${action.payload.id}/`
            apiDelete(url, () => {
                notifySuccess("Action list successfully deleted")
                dispatch(getChecklistsStatus(), e => notifyApiFailure(`deleting checklist`, 'DELETE', url, data, e.status))
            })
            break
        case ActionTypes.DELETE_MULTIPLE_CHECKLISTS:
            url = `checklists/delete/`
            data = {ids: action.payload}
            apiPost(url, data, () => {
                notifySuccess("Action lists successfully deleted")
                dispatch(getChecklistsStatus(), e => notifyApiFailure(`deleting checklists`, 'POST', url, data, e.status))
            })
            break
        case ActionTypes.GET_CHECKLIST:
            url =`checklists/checklist/${action.payload}`
            apiGet(url, checklistResult(dispatch, action), e => {
                if(e.status === 404) dispatch(checklistNotFound())
                else if(e.status === 403) dispatch(checklistNotAccessible())
                else notifyApiFailure(`getting Action list`, 'GET', url, data, e.status)
            })
            break
        case ActionTypes.GET_CHECKLIST_ITEMS:
            url =`checklists/checklist/${action.payload}/items`
            apiGet(url, result => dispatch(gotChecklistItems({items: result, checklistId: action.payload})), e => notifyApiFailure(`getting checklist items`, 'GET', url, data, e.status))
            break
        case ActionTypes.GET_CHECKLIST_CATEGORY_ITEMS:
            url = `checklists/checklist/${action.payload.id}/category_items?offset=${action.payload.offset}&limit=${action.payload.limit}&done=${action.payload.category}&sortIndex=${action.payload.sortIndex}&sortDirection=${action.payload.sortDirection}`
            apiGet(url, result => dispatch(gotChecklistCategoryItems({items: result.items, total: result.total, kpiTotal: result.kpiTotal, checklistId: action.payload.id, category: action.payload.category, reset: action.payload.reset})), e => notifyApiFailure('getting checklist category items', 'GET', url, data, e.status))
            break
        case ActionTypes.GET_CHECKLIST_COMMENTS:
            url = `checklists/checklist/${action.payload}/comments`
            apiGet(url, result => {
                dispatch(gotChecklistComments(result, action.payload.bucket_id))
            }, e => notifyApiFailure(`getting item comments on Action list`, 'GET', url, data, e.status))
            break
        case ActionTypes.CHANGE_STATUS:
            url =`checklists/item_status`
            data = {items: action.payload.items, status: action.payload.status, skip_layout: action.payload.skip_layout}
            apiPost(url,
                data,
                result => {
                    action.payload.cb()
                }, e => notifyApiFailure(`changing the status of the items`, 'POST', url, data, e.status)
            )
            break
        case ActionTypes.DELETE_CHECKLIST_ITEMS:
            url =`checklists/items/delete`
            data = action.payload
            apiPost(url, data, result => {
                notifySuccess("Items successfully deleted")
                dispatch(getChecklistItems(action.payload.checklist_id))
            }, e => notifyApiFailure(`removing items from the Action list`, 'POST', url, data, e.status))
            break
        case ActionTypes.COMPLETE_CHECKLIST:
            url =`checklists/checklist/complete/${action.payload.id}`
            data = action.payload
            apiPost(url, data, result => {
                notifySuccess("Action list successfully completed")
                dispatch(packAction(ActionTypes.GET_CHECKLIST, action.payload.id))
            }, e => notifyApiFailure(`completing Action list`, 'POST', url, data, e.status))
            break
        case ActionTypes.REACTIVATE_CHECKLIST:
            url =`checklists/checklist/reactivate/${action.payload}`
            apiPost(url, data, result => {
                notifySuccess("Action list is now reactivated")
                dispatch(packAction(ActionTypes.GET_CHECKLIST, action.payload))
            }, e => notifyApiFailure(`reactivating Action list`, 'POST', url, data, e.status))
            break
        case ActionTypes.SPLIT_CHECKLIST:
            url = `checklists/checklist/split/${action.payload.id}`
            data = action.payload
            apiPost(url, data, result => {
                notifySuccess("Action list successfully splitted")
                dispatch(packAction(ActionTypes.GET_CHECKLISTS_STATUS))
            }, e => notifyApiFailure(`splitting Action list`, 'POST', url, data, e.status))
            break
        case ActionTypes.GET_LOCAL_CHECKLIST_COMMENTS:
            url = `checklists/local_comments/${action.payload.checklist_id}/${action.payload.item_id}`
            apiGet(url, result => {
                dispatch(gotPastedChecklistComments(action.payload.item_id, result))
            }, e => notifyApiFailure(`getting Action list`, 'GET', url, data, e.status))
            break
        case ActionTypes.GET_ALL_LOCAL_CHECKLIST_COMMENTS:
            url = `checklists/local_comments/${action.payload.checklist_id}/all`
            apiGet(url, result => {
                dispatch(gotAllPastedChecklistComments(result))
            }, e => notifyApiFailure(`getting Action list comments`, 'GET', url, data, e.status))
            break
        case ActionTypes.CREATE_LOCAL_CHECKLIST_COMMENT:
            url = `checklists/local_comments/${action.payload.checklist_id}/${action.payload.item_id}`
            data = action.payload
            apiPost(url, data, result => {
                dispatch(updatedPastedComment(action.payload.checklist_id, action.payload.item_id))
            }, e => notifyApiFailure(`creating comment on Action list`, 'POST', url, data, e.status))
            break
        case ActionTypes.UPDATE_LOCAL_CHECKLIST_COMMENT:
            url =`checklists/local_comments/${action.payload.id}`
            data = action.payload
            apiPut(url, data, result => {
                dispatch(updatedPastedComment(action.payload.checklist_id, action.payload.item_id))
            }, e => notifyApiFailure(`saving comment on Action list`, 'PUT', url, data, e.status))
            break
        case ActionTypes.DELETE_LOCAL_CHECKLIST_COMMENT:
            url = `checklists/local_comments/${action.payload.id}`
            apiDelete(url, result => {
                dispatch(updatedPastedComment(action.payload.checklist_id, action.payload.item_id))
            }, e => notifyApiFailure(`deleting comment from Action list`, 'DELETE', url, data, e.status))
            break
        case ActionTypes.GET_CHECKLIST_ITEM_DATA:
            url = `checklists/checklist/${action.payload.checklist_id}/item/${action.payload.item_key}`
            apiGet(url, result => {
                dispatch(gotChecklistItemData({item: result, checklist_id: action.payload.checklist_id}))
            }, e => notifyApiFailure(`getting Action list item data`, 'GET', url, data, e.status))
            break
        case ActionTypes.UPLOAD_LOCAL_CHECKLIST:
            url = `checklists/paste`
            data = action.payload
            apiPost(url, data, result => {
                window.location.hash = `/checklist/${result.id}`
            }, e => notifyApiFailure(`uploading pasted Action list`, 'POST', url, data, e.status))
            break
        case ActionTypes.SAVE_LAYOUT:
            url = `checklists/layout`
            data = action.payload
            apiPost(url, data, result => {
                notifySuccess("Action list layout saved")
                dispatch(newLayout(result))
                dispatch(getLayouts())
            }, e => notifyApiFailure(`saving layout`, 'POST', url, data, e.status))
            break
        case ActionTypes.UPDATE_LAYOUT:
            url = `checklists/layout/${action.payload.id}`
            data = action.payload
            apiPut(url, data, result => {
                notifySuccess("Action list layout updated")
                dispatch(getLayouts())
            }, e => notifyApiFailure(`updating layout`, 'PUT', url, data, e.status))
            break
        case ActionTypes.GET_LAYOUTS:
            url = `checklists/layout`
            apiGet(url, result => {
                dispatch(gotLayouts(result))
            }, e => notifyApiFailure(`getting layouts`, 'GET', url, data, e.status))
            break
        case ActionTypes.DELETE_LAYOUT:
            url = `checklists/layout/${action.payload}`
            apiDelete(url, result => {
                notifySuccess("Action list layout deleted")
                dispatch(getLayouts())
            }, e => notifyApiFailure(`deleting layout`, 'DELETE', url, data, e.status))
            break
        case ActionTypes.UPDATE_CHECKLIST_ITEMS:
            url = `checklists/checklist/${action.payload.id}/update_items`
            apiPost(url, null, result => {
                checklistResult(dispatch, {payload: result.id})(result)
                dispatch(packAction(ActionTypes.GET_FOLLOWINGS))
                notifySuccess("The Action list has been updated.")
                dispatch(getChecklistsStatus())
                dispatch(resetChecklistItems(result.id))
                if (action.payload.redirect) {
                    window.location.hash = `/checklist/${result.id}`
                }
            }, e => {
                notifyApiFailure(`updating Action list items`, 'GET', url, data, e.status)
            })
            break
        case ActionTypes.UPDATE_CHECKLIST_ITEMS_MULTIPLE:
            url = `checklists/update_items`
            apiPost(url, action.payload, result => {
                notifySuccess("The Action lists has been updated.")
                dispatch(packAction(ActionTypes.GET_FOLLOWINGS))
                dispatch(getHomeInfo())
            }, e => {
                notifyApiFailure(`updating Action list items from home`, 'GET', url, data, e.status)
            })
            break
        case ActionTypes.DUPLICATE_CHECKLIST:
            url = `checklists/checklist/duplicate/${action.payload.id}`
            apiPost(url, null, result => {
                if (action.payload.redirect){
                    window.location.hash = `/checklist/${result}`
                } else {
                    updatedChecklist(dispatch, result)
                }
                notifySuccess("Successfully duplicated Action list.")
            }, e => {
                notifyApiFailure(`duplicate Action list`, 'POST', url, {id: action.payload.id}, e.status)
            })
            break
        case ActionTypes.DOWNLOAD_CHECKLIST_DATA:
            url=`checklists/checklist/download/fetchdata/${action.payload.id}`
            data=action.payload.data
            apiPost(url, data, result => {
                //do nothing, a notification should already have been sent
            }, e => {
                notifyApiFailure(`Downloading Action list`, 'POST', url, {id: action.payload.id}, e.status)
            })
            break
        case ActionTypes.GET_ACTION_TEMPLATES:
            url = 'action_templates'
            data = action.payload
            apiGet(url, (result) => {
                dispatch(gotActionTemplates(result))
            }, e => notifyApiFailure('getting action templates', 'GET', url, data, e.status))
            break
        case ActionTypes.CREATE_ACTION_FROM_TEMPLATE:
            url = 'action_templates'
            data = action.payload
            apiPost(url, data, (result) => {
                action.cb(result)
            }, e => {
                    notifyApiFailure('creating action from template', 'POST', url, data, e.status)
                    action.errCb()
                })
            break
        default:
            break
    }
}

const checklistResult = (dispatch, action) => {
    return result => {
        dispatch(gotChecklist(result, action.payload))
        //dispatch(getUsers()) // This is not really necessary...
        if(result.bucket !== "" && result.bucket !== null && result !== undefined) {
            dispatch(packAction(ActionTypes.GET_CHECKLIST_COMMENTS, action.payload))
        } else {
            dispatch(packAction(ActionTypes.GET_ALL_LOCAL_CHECKLIST_COMMENTS, {checklist_id: action.payload}))
        }
    }
}

export default ChecklistApiMiddleware
